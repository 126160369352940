import React from "react";
import constants from "../../constants";
import { Link } from "@reach/router";
import moment from "moment";

const Footer = () => {
  return (
    <div className="container-fluid bg-dark-gray py-4 px-md-5 px-4 text-white">
      <div className="row border-bottom pb-3 mb-3">
        <div className="col-12 col-md-8 px-0">
          <h5 className="d-inline-block font-poppins text-sentence">
            Everything you want in a workout
          </h5>
          <a
            href="https://www.facebook.com/thebodymethodmx"
            className="no-decoration text-white hide-mobile"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-facebook footer-icon mx-2"></i>
          </a>
          <a
            href="https://www.instagram.com/thebodymethodmx/"
            className="no-decoration text-white hide-mobile"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-instagram footer-icon mx-2"></i>
          </a>
        </div>
        <div className="col-6 px-0 show-mobile">
          <a
            href="https://www.facebook.com/thebodymethodmx"
            className="no-decoration text-white"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-facebook footer-icon mx-2"></i>
          </a>
          <a
            href="https://www.instagram.com/thebodymethodmx/"
            className="no-decoration text-white"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-instagram footer-icon mx-2"></i>
          </a>
        </div>
        <div className="col-6 col-md-4 px-0 text-end">
          <Link to="/ubicacion" className="text-white no-decoration">
            Ubicación y Contacto
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 px-0 mobile-left small mb-2">
          <Link
            to="/terminos"
            className="text-white d-md-inline-block d-block m-2 no-decoration"
          >
            Términos y condiciones
          </Link>
          <Link
            to="/terminos"
            className="text-white m-2 no-decoration d-md-inline-block d-block"
          >
            Política de privacidad
          </Link>
          <Link
            to="/preguntas-frecuentes"
            className="text-white m-2 no-decoration d-md-inline-block d-block"
          >
            Preguntas frecuentes
          </Link>
        </div>
        <div className="col-12 col-md-6 px-0 text-end mobile-center my-2">
          <span>
            &copy; {moment().year()} {constants.business_name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
