import React, { useState } from "react";
import SingleClassData from "./SingleClassData";
// import MapaLugaresMonterrey from "./MapaLugaresMonterrey";

const SingleClassMonterrey = ({ clase, postReservacion, hideModal }) => {
  const [place, setPlace] = useState(null);

  return (
    <div className="container-fluid px-0">
      <SingleClassData single_class={clase} size="lg" />
      {/* <MapaLugaresMonterrey place={place} setPlace={setPlace} class_type={clase?.class_type} /> */}
      <div className="row">
        <div className="col col-md-6">
          <button className="btn w-100 text-muted" onClick={hideModal}>
            Cancelar
          </button>
        </div>
        <div className="col col-md-6">
          <button
            className="btn w-100 btn-primary"
            onClick={() => postReservacion({ ...clase, spot: place })}
          >
            Reservar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleClassMonterrey;
