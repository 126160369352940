import React from "react";
import {
  daysArray,
  getClassCategoryDay,
  isDateBeforeStartOrAfterEnd,
} from "../../utils/workout_planner";
import useWorkoutPlanner from "../../hooks/useWorkoutPlanner";
import PlannerWorkoutDay from "./PlannerWorkoutDay";
import PlannerRestDay from "./PlannerRestDay";
import PlannerGrayDay from "./PlannerGrayDay";
import moment from "moment";

const PlannerWeek = ({
  weekIndex,
  start_date,
  handleClick,
  workout_plan,
  max_end_date,
  min_start_date,
  customer_workout_plan,
}) => {
  const { hasCompletedDay } = useWorkoutPlanner();

  const renderDays = () => {
    let currentComponents = [
      <div key={`semana-${weekIndex + 1}`} className="schedule-col px-0 py-0">
        <div className="card h-100 br-0 small text-center bg-light p-2 py-4">
          <span className="d-block mt-3">Semana {weekIndex}</span>
        </div>
      </div>,
    ];
    daysArray.forEach((dayIndex, index) => {
      const currentDate = moment(start_date).add(index, "days");
      const currentDateString = currentDate.format("DD / MMM");
      if (
        isDateBeforeStartOrAfterEnd(currentDate, min_start_date, max_end_date)
      ) {
        currentComponents.push(
          <PlannerGrayDay key={`${dayIndex}-${weekIndex}`} />
        );
      } else {
        const completedDay = hasCompletedDay(currentDate.format("YYYY-MM-DD"));
        const classCategoryDay = getClassCategoryDay(
          workout_plan,
          customer_workout_plan,
          dayIndex
        );
        if (classCategoryDay === undefined) {
          currentComponents.push(
            <PlannerRestDay
              key={`${dayIndex}-${weekIndex}`}
              completedDay={completedDay}
            />
          );
        } else {
          const { class_category } = classCategoryDay;
          currentComponents.push(
            <PlannerWorkoutDay
              key={`${dayIndex}-${weekIndex}`}
              dayIndex={dayIndex}
              weekIndex={weekIndex}
              handleClick={handleClick}
              workout_plan={workout_plan}
              completedDay={completedDay}
              class_category={class_category}
              currentDateString={currentDateString}
            />
          );
        }
      }
    });
    return Array.from(currentComponents);
  };

  return (
    <div className="row" key={weekIndex}>
      {renderDays()}
    </div>
  );
};

export default PlannerWeek;
