import React, { useState, useEffect, useContext } from "react";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import useBranch from "../../hooks/useBranch";
import "./MonterreySchedule.css";
import MonterreyScheduleHeader from "./MonterreyScheduleHeader";
import MonterreyScheduleClasses from "./MonterreyScheduleClasses";
import { useClassesByHour } from "../../hooks/schedule/useClassesByHour";
import MonterreyColorLegend from "../monterrey/MonterreyColorLegend";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import ClassTypesCaption from "../classTypes/ClassTypesCaption";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";
import { AuthContext } from "../../context/AuthContext";
import { ReservationsContext } from "../../context/ReservationsContext";
import { SingleClassContext } from "../../context/SingleClassContext";

const MonterreySchedule = () => {
  const [view, setView] = useState("week");
  // const [location, setLocation] = useState("");
  const [currentClasses, setCurrentClasses] = useState(null);

  const { branch } = useBranch();

  const {
    days,
  } = useContext(SingleClassContext);

  const { user } = useContext(AuthContext);
  const { getClassTypes } = useContext(ClassTypeContext);
  const { getMyReservations } = useContext(ReservationsContext);
  // const { locations, getLocations } = useContext(LocationsContext);
  const { getAvailableClasses } = useContext(AvailableClassesContext);

  useEffect(() => {
    getClassTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  useEffect(() => {
    if (branch && branch !== null && user) {
      getMyReservations({ branch_id: branch.branch_id });
      getAvailableClasses({ branch_id: branch.branch_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, user]);

  const { formatClasses, morningClasses, eveningClasses, startsAm } =
    useClassesByHour();

  useEffect(() => {
    if(Array.isArray(currentClasses)) {
      formatClasses(currentClasses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClasses]);

  const renderMorningHours = () => {
    return Object.keys(morningClasses).map((hour) => {
      return (
        <div
          key={`${hour}-am`}
          className="schedule__class-hour d-flex align-items-center text-start ps-0"
        >
          {hour}
        </div>
      );
    });
  };

  const renderEveningHours = () => {
    return Object.keys(eveningClasses).map((hour) => {
      return (
        <div
          key={`${hour}-pm`}
          className="schedule__class-hour d-flex align-items-center text-start ps-0"
        >
          {hour}
        </div>
      );
    });
  };

  const renderEveningDivision = () => {
    if (startsAm) {
      return (
        <div
          style={{ height: "80px" }}
          className=" text-black schedule__class-hour d-flex align-items-center text-start bold ps-0"
        >
          PM
        </div>
      );
    }
  };

  const renderSchedule = () => {
    const scheduleHasClasses =
      Object.keys(eveningClasses).length > 0 ||
      Object.keys(morningClasses).length > 0;

    if (scheduleHasClasses) {
      return (
        <>
          <div
            className="d-flex flex-column container__time-column"
            style={{ width: "60px" }}
          >
            <div
              className="text-black bold text-start ps-0"
              style={{ height: "74px", paddingTop: "37px" }}
            >
              {startsAm ? "AM" : "PM"}
            </div>
            {renderMorningHours()}
            {renderEveningDivision()}
            {renderEveningHours()}
          </div>

          <div className="d-flex container__content flex-column">
            <MonterreyScheduleHeader startsAm={startsAm} />
            <MonterreyScheduleClasses
              morningClasses={morningClasses}
              eveningClasses={eveningClasses}
              startsAm={startsAm}
            />
          </div>
        </>
      );
    }

    return (
      <div className="row py-3">
        <p className="mb-0">No hay más clases programadas esta semana.</p>
      </div>
    );
  };

  return (
    <div className="container-fluid px-0 mb-4">
      <div className="row w-100 mx-auto me-0 align-items-center">
        <div className="col-12 col-md-4 mb-3 px-0">
          <ScheduleMonthSelect />
        </div>
        <div className="col-12 col-md-4 mb-3 px-0">
          <ScheduleWeekSelect />
        </div>
        <div className="col-12 col-md-4 mb-3 ps-0">
          <MonterreyColorLegend />
          {/*
          <ScheduleLocationPicker
            locations={locations}
            modifier={setLocation}
            selectedLocation={location}
          />
          */}
        </div>
      </div>

      <div className="d-flex w-100 schedule__class-types mx-auto">
        <ClassTypesCaption />
      </div>

      <div className="container-fluid schedule__container mt-4">
        {renderSchedule()}
      </div>
    </div>
  );
};

export default MonterreySchedule;
