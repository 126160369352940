import React, { useState } from "react";
import MapaLugares from "./MapaLugares";
import SingleClassData from "./SingleClassData";

const SingleClass = ({ clase, postReservacion, hideModal }) => {
  const [place, setPlace] = useState(null);

  return (
    <div className="container-fluid px-0">
      <SingleClassData single_class={clase} />
      {clase.class_type !== null && clase.class_type.spot_map !== null && (
        <MapaLugares
          place={place}
          setPlace={setPlace}
          rows={clase.class_type.spot_map
            .split(",")
            .map((num) => parseInt(num))}
          icon={clase.icon}
          taken_spots={clase.taken_spots ? clase.taken_spots : []}
        />
      )}
      <div className="row">
        <div className="col col-md-6">
          <button className="btn w-100 text-muted" onClick={hideModal}>
            Cancelar
          </button>
        </div>
        <div className="col col-md-6">
          <button
            className="btn w-100 btn-primary"
            onClick={() => postReservacion({ ...clase, place })}
          >
            Reservar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleClass;
