import React, { useContext, useEffect } from "react";
import { navigate } from "@reach/router";
import Quote from "../components/quote/Quote";
import { AuthContext } from "../context/AuthContext";
import { ModalContext } from "../context/ModalContext";
import StreakWeek from "../components/streaks/StreakWeek";
import { isCustomerInfoComplete } from "../utils/customer";
import PanelClasses from "../components/panel/PanelClasses";
import PanelGreeting from "../components/panel/PanelGreeting";
import CustomerCompleteInfo from "../components/customers/CustomerCompleteInfo";
import { CustomerWorkoutPlansContext } from "../context/CustomerWorkoutPlansContext";

const PanelHome = () => {
  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { customer_workout_plan, getActiveCustomerWorkoutPlan } = useContext(
    CustomerWorkoutPlansContext
  );

  useEffect(() => {
    getActiveCustomerWorkoutPlan();
    handleUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleQuote = () => {
    modalComponent("", <Quote handleCancel={clearModal} />, {
      size: "lg",
      centered: true,
      hideClose: true,
      no_padding: true,
    });
  };

  const handleUserInfo = () => {
    const infoComplete = isCustomerInfoComplete(user);
    if (!infoComplete) {
      modalComponent("Completa tu Información", <CustomerCompleteInfo />);
    }
  };

  const handleWorkoutPlanner = () => {
    if (customer_workout_plan && customer_workout_plan !== null) {
      return navigate(
        `/mytbm/workout-planner/${customer_workout_plan.customer_workout_plan_id}`
      );
    }
    navigate("/pages/workout-planner");
  };

  return (
    <div id="greeting" className="container-fluid px-4">
      <StreakWeek />
      <div className="row mx-0 my-5 h-100 align-items-center">
        <div className="col-12 col-md-4">
          <PanelGreeting
            handleQuote={handleQuote}
            handleWorkoutPlanner={handleWorkoutPlanner}
          />
        </div>
        <div className="col-12 col-md-8">
          <PanelClasses />
        </div>
      </div>
    </div>
  );
};

export default PanelHome;
