import {
  SET_MILESTONE_BENEFIT,
  CREATE_MILESTONE_BENEFIT,
  MILESTONE_BENEFITS_RECEIVED,
  SET_PROPERTY_MILESTONE_BENEFIT,
} from "../types/milestone_benefits";

const schema = {};

const MilestoneBenefitsReducer = (state, { type, payload }) => {
  switch (type) {
    case MILESTONE_BENEFITS_RECEIVED:
      return { ...state, milestone_benefits: payload };
    case SET_MILESTONE_BENEFIT:
      return { ...state, milestone_benefit: payload };
    case CREATE_MILESTONE_BENEFIT:
      return { ...state, milestone_benefit: schema };
    case SET_PROPERTY_MILESTONE_BENEFIT: {
      const { key, value } = payload;
      const milestone_benefit = { ...state.milestone_benefit };
      milestone_benefit[key] = value;
      return { ...state, milestone_benefit };
    }
    default:
      return { ...state };
  }
};

export default MilestoneBenefitsReducer;
