import React from "react";
import MonterreyLandingVideo from "../components/monterrey/MonterreyLandingVideo";
import MonterreyLandingAbout from "../components/monterrey/MonterreyLandingAbout";
import MonterreyLandingCoaches from "../components/monterrey/MonterreyLandingCoaches";
import MonterreyLandingClassTypes from "../components/monterrey/MonterreyLandingClassTypes.js";
import MonterreyLandingSchedule from "../components/monterrey/MonterreyLandingSchedule.js";
import MonterreyLandingAccordion from "../components/monterrey/MonterreyLandingAccordion.js";
import MonterreyLandingPackages from "../components/monterrey/MonterreyLandingPackages.js";
import MonterreyLandingBanner from "../components/monterrey/MonterreyLandingBanner.js";
import MonterreyLandingLocations from "../components/monterrey/MonterreyLandingLocations.js";
import MonterreyFooter from "../components/monterrey/MonterreyFooter.js";

const LandingMonterrey = () => {
  return (
    <div 
      id="landing"
      className="vw-100 position-relative" 
      style={{ overflow: 'hidden'}}
      data-bs-spy="scroll"
      data-bs-target="#monterrey-landing"
      data-bs-root-margin="0px 0px -40%"
      data-bs-smooth-scroll="true"
      tabIndex="0"
    >
      <MonterreyLandingVideo />
      <MonterreyLandingAbout />
      <MonterreyLandingClassTypes />
      <MonterreyLandingCoaches/>
      <MonterreyLandingSchedule />
      <MonterreyLandingAccordion/>
      <MonterreyLandingPackages/>
      <MonterreyLandingBanner/>
      <MonterreyLandingLocations/>
      <MonterreyFooter/>
    </div>
  );
};

export default LandingMonterrey;
