import React from "react";

const SingleMilestoneBenefit = ({ milestone_benefit }) => {
  const renderContent = () => {
    if (milestone_benefit.type === "video") {
      const video_source = milestone_benefit.src;
      if (video_source.includes("vimeo")) {
        return (
          <iframe
            className="video-iframe"
            title={milestone_benefit.name}
            src={`https://player.vimeo.com/video/${video_source}`}
            allowFullScreen
          ></iframe>
        );
      }
      if (video_source.includes("youtube")) {
        const source =
          "https://www.youtube.com/embed/" + video_source.split("v=")[1];
        return (
          <div className="my-3">
            <iframe
              src={source}
              title="YouTube video player"
              className="mw-100 w-100 shadow"
              height="500px"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        );
      }
      if (video_source.includes("drive")) {
        return (
          <div className="p-3">
            <iframe
              src={video_source}
              title={milestone_benefit.name}
              className="mw-100 w-100"
              height="auto"
              width="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        );
      }
      return (
        <video className="mw-100 w-100" controls>
          <source src={video_source} type="video/mp4" />
        </video>
      );
    }
  };

  return <div>{renderContent()}</div>;
};

export default SingleMilestoneBenefit;
