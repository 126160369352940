import React, { useContext, useEffect, useState } from "react";
import { Link } from "@reach/router";
import { ModalContext } from "../context/ModalContext";
import Pagination from "../components/global/Pagination";
import CancelClass from "../components/clases/CancelClass";
import { ReservationsContext } from "../context/ReservationsContext";
import ReservacionesTable from "../components/reservaciones/ReservacionesTable";
import EmptyReservations from "../components/reservaciones/EmptyReservations";
import ReservationsList from "../components/reservaciones/ReservationsList";
import { AvailableClassesContext } from "../context/AvailableClassesContext";
import useBranch from "../hooks/useBranch";

const MisReservaciones = () => {
  const [page, setPage] = useState(1);
  const [filter, setFiler] = useState("all");
  const {
    max,
    reservations,
    getMyReservations,
    clearReservations,
    cancelReservacion,
  } = useContext(ReservationsContext);
  const { modalComponent } = useContext(ModalContext);
  const { available_classes, getAvailableClasses } = useContext(
    AvailableClassesContext
  );

  const { branch, branch_id } = useBranch();

  useEffect(() => {
    clearReservations();
    getAvailableClasses({ branch_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  useEffect(() => {
    getMyReservations({ page, filter, branch_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, branch_id]);

  const confirmCancel = (reservation) => {
    modalComponent(
      "Cancelar Clase",
      <CancelClass
        singleClass={reservation}
        cancelReservacion={cancelReservacion}
      />
    );
  };

  const renderReservaciones = () => {
    if (Array.isArray(reservations)) {
      if (reservations.length === 0) return <EmptyReservations />;
      return (
        <div className="mb-2">
          <ReservacionesTable
            confirmCancel={confirmCancel}
            reservations={reservations}
          />
          <ReservationsList
            confirmCancel={confirmCancel}
            reservations={reservations}
          />
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };
  
  return (
    <div className="container-fluid px-4 mx-md-3 pe-md-5">
      <div className="row align-items-center my-3">
        <div className="col-12 col-md-8">
          <h1 className="mb-0">Mis Reservaciones</h1>
          <p className="mb-0">
            Sólo puedes cancelar clases con 1 hora de anticipación
          </p>
        </div>
        <div className="col-12 col-md-4 text-end">
          <h4 className="mb-3 me-2">Restantes: {available_classes?.length}</h4>
          <Link to="/mytbm/paquetes" className="btn btn-primary">
            Comprar Clases
          </Link>
        </div>
      </div>
      <div className="btn-group d-block mb-3">
        <button onClick={() => setFiler("all")} className={`btn btn-sm btn-${filter === "all" ? "primary": "outline-primary"}`}>
          Todas
        </button>
        <button onClick={() => setFiler("upcoming")} className={`btn btn-sm btn-${filter === "upcoming" ? "primary": "outline-primary"}`}>
          Próximas
        </button>
        <button onClick={() => setFiler("past")} className={`btn btn-sm btn-${filter === "past" ? "primary": "outline-primary"}`}>
          Pasadas
        </button>
        <button onClick={() => setFiler("cancelled")} className={`btn btn-sm btn-${filter === "cancelled" ? "primary": "outline-primary"}`}>
          Canceladas
        </button>
      </div>
      {renderReservaciones()}
      <div className="mb-4">
        <Pagination
          value={page}
          setValue={setPage}
          maxPages={max !== null ? Math.ceil(max / 12) : 0}          
        />
      </div>
    </div>
  );
};

export default MisReservaciones;
