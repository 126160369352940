import moment from "moment";
import React from "react";
import SingleCoach from "../coaches/SingleCoach";
import { getValue } from "../../utils";

const SingleClassData = ({ size, single_class }) => {
  const renderCoaches = () => {
    if (Array.isArray(single_class.class_instructors)) {
      return single_class.class_instructors.filter(({ instructor }) => instructor && instructor !== null).map((class_instructor) => {
        return (
          <SingleCoach
            key={class_instructor.instructor.instructor_id}
            coach={class_instructor.instructor}
          />
        );
      });
    }
  };

  const renderIcon = () => {
    if (single_class.class_type && single_class.class_type !== null) {
      return (
        <>
          <i className={single_class.class_type.icon} />{" "}
          {single_class.class_type.name}
        </>
      );
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className={size === "lg" ? "col-12 col-md-6 ps-0" : "col-12"}>
          <h4 className="mb-1">{renderIcon()}</h4>
          <h3>{single_class.description}</h3>
          <p className="large">
            <i className="far fa-calendar me-2"></i>{" "}
            {moment(single_class.class_date).format("DD MMM YYYY")}{" "}
            <i className="far fa-clock mx-2"></i>{" "}
            {getValue(single_class, "class_date", "time")} hrs.
          </p>
          <h5>Ubicación</h5>
          <p className="large">{single_class.location.name}</p>
          <p>{single_class.location.address}</p>
        </div>
        <div className={size === "lg" ? "col-12 col-md-6 pe-0" : "col-12"}>
          <h5 className="mt-4">Coaches</h5>
          {renderCoaches()}
        </div>
      </div>
    </div>
  );
};

export default SingleClassData;
