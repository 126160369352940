import { getArgs } from "../utils";
import api from "./api";

const route = "/single_class";

const ClassInstructorService = {
  getWeeks: (params) =>
    api.get(`${route}?${getArgs(params)}`),
  getClase: (class_instructor_id) =>
    api.get(`${route}/single/${class_instructor_id}`),
  getClases: (page) => api.get(`${route}/admin?page=${page}`),
  getMyReservations: () => api.get(`${route}/myreservations`),
  postReservacion: (clase) => api.post(`${route}/reservation`, { ...clase }),
  putReservacion: (clase) => api.put(`${route}/reservation`, { ...clase }),
  cancelReservacion: (class_reservation_id) =>
    api.delete(`${route}/${class_reservation_id}`),
  postClase: (clase) => api.post(route, { ...clase }),
  putClase: (clase) => api.put(route, { ...clase }),
  postAttend: (class_reservation_id, attend) =>
    api.post(`${route}/attend`, { class_reservation_id, attend }),
  postPayment: (class_reservation_id, is_paid) =>
    api.post(`${route}/payment`, { class_reservation_id, is_paid }),
  postAsistenteClase: (customer_id, class_instructor_id, is_cash, is_paid) =>
    api.post(`${route}/asistente`, {
      customer_id,
      class_instructor_id,
      is_cash,
      is_paid,
    }),
  updateGuestName: (class_reservation_id, name) =>
    api.put(`${route}/guest`, { class_reservation_id, name }),
  deleteClase: (class_instructor_id) =>
    api.delete(`${route}/classInstructor/${class_instructor_id}`),
  deleteAsistente: (class_reservation_id) =>
    api.delete(`${route}/asistente/${class_reservation_id}`),
};

export default ClassInstructorService;
