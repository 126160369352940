import React, { useState, useContext } from "react";
import { CartContext } from "../../context/CartContext";
import { ModalContext } from "../../context/ModalContext";

const CustomerFreeTrial = ({ handleCallback }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { postCart } = useContext(CartContext);
  const { clearModal } = useContext(ModalContext);

  const handleFreeTrial = (e) => {
    e.preventDefault();
    setLoading(true);
    postCart({ email, class_package_id: 19271 });
    window.localStorage.setItem("email", email);
    setTimeout(() => {
      handleCallback();
      setLoading(false);
      clearModal();
    }, 500);
  };

  return (
    <form onSubmit={handleFreeTrial}>
      <label>Ingresa tu correo electrónico</label>
      <input
        type="email"
        value={email}
        className="form-control"
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        type="submit"
        disabled={loading}
        className="btn btn-primary w-100 mt-3"
      >
        {loading ? <div className="spinner-border" /> : "Continuar"}
      </button>
    </form>
  );
};

export default CustomerFreeTrial;
