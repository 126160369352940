import React, { useContext, useEffect } from "react";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import "./MonterreyLandingClassTypes.css";
import ClassTypesCarousel from "../classTypes/ClassTypesCarousel";

const MonterreyLandingClassTypes = () => {
  const { class_types, getClassTypes } = useContext(ClassTypeContext);

  useEffect(() => {
    getClassTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="scrollspyMtyClassTypes" className="container-fluid monterrey-landing-class-types mt-5">
      <div className="row w-100 mx-auto">
        <p className="monterrey-landing-class-types__title p-0">EVERYTHING YOU WANT IN A WORKOUT</p>
        <p className="monterrey-landing-class-types__subtitle p-0">
          Disfruta de nuestro método combinando diferentes disciplinas, clases
          de FUERZA, CARDIO, BARRE & PILATES
        </p>

        <p className="monterrey-landing-class-types__description bold text-black p-0"> ¡Conoce los beneficios de cada una de nuestras clases!</p>
      </div>

      <div className="container-fluid px-0 pe-md-3">
        <ClassTypesCarousel class_types={class_types?.filter(({ file }) => file !== null)}/>
      </div>
    </div>
  );
};

export default MonterreyLandingClassTypes;
