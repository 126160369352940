import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { cardStyle } from "../../utils";
import { ModalContext } from "../../context/ModalContext";
import { Link } from "@reach/router";
import { AuthContext } from "../../context/AuthContext";
import { PaymentSourcesContext } from "../../context/PaymentSourcesContext";

const StripeCardForm = ({ }) => {
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { alert } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const { postStripePaymentSource } = useContext(PaymentSourcesContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!accept) {
      return alert(
        "Debes aceptar los términos y condiciones para poder comprar."
      );
    }

    setLoading(true);
    const card = elements.getElement(CardElement);
    const payment_method = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });    
    
    await postStripePaymentSource(payment_method);
    setLoading(false);
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <h5 className="border-bottom pb-2 mb-3">Tarjeta de Crédito/Débito</h5>
        <CardElement
          id="card-element"
          options={cardStyle}
          className="form-control pt-2 my-2"
          onChange={handleChange}
        />
        <div className="container-fluid px-0 py-2 mb-2">
          <label>
            <input
              type="checkbox"
              checked={accept}
              className="d-inline-block me-2"
              onChange={() => setAccept(!accept)}
            />
            Acepto los{" "}
            <Link to="/terminos-y-condiciones">términos y condiciones</Link>.
          </label>
        </div>
        <button
          className="btn btn-success btn-checkout bold"
          disabled={loading || disabled}
        >
          {loading ? <div className="spinner-border"></div> : "Guardar Método de Pago"}
        </button>
      </form>
    </div>
  );
};

export default StripeCardForm;
