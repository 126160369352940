import React, { useState, useEffect, useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import { LocationsContext } from "../../context/LocationsContext";
import ScheduleLocationPicker from "./ScheduleLocationPicker";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import ColorLegend from "../global/ColorLegend";
import ScheduleDay from "./ScheduleDay";
import utils from "./utils";

const Schedule = () => {
  const [location, setLocation] = useState("");
  const [currentClasses, setCurrentClasses] = useState(null);

  const { days } = useContext(SingleClassContext);
  const { locations } = useContext(LocationsContext);

  useEffect(() => {
    if (Array.isArray(days)) {
      setCurrentClasses(days.slice(0, 7));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const renderDays = () => {
    if (Array.isArray(currentClasses)) {
      if (!utils.hasClases(currentClasses)) {
        return (
          <div className="row py-3">
            <p className="mb-0">No hay más clases programadas esta semana.</p>
          </div>
        );
      }
      return currentClasses.map((day) => (
        <ScheduleDay
          day={day}
          key={day.date}
          location={location}
          clases={day.details}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-0 mb-4">
      <div className="row me-0 align-items-center">
        <div className="col-12 col-md-4 mb-3">
          <ScheduleMonthSelect />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <ScheduleWeekSelect />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <ColorLegend />
          <ScheduleLocationPicker
            locations={locations}
            modifier={setLocation}
            selectedLocation={location}
          />
        </div>
      </div>
      <div className="container-fluid schedule-mobile-container">
        <div className="schedule-mobile-wrapper">
          <div className="row">{renderDays()}</div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
