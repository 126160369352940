import React from "react";
import "./MonterreyLandingAbout.css";
import { useNavigate } from "@reach/router";
import { trialClassCheckoutRoute } from "../../utils";

const MonterreyLandingAbout = () => {
  const navigate = useNavigate();

  return (
    <div id="scrollspyAbout" className="container-fluid monterrey-about">
      <div className="row w-100 mx-auto" style={{ maxWidth: '1246px'}}>
        {/* Mobile Image */}
        <div className="col-12 show-mobile px-0">
          <img
            alt="tbm members"
            src={"/img/monterrey-about.png"}
            className="w-100"
          />
        </div>

        {/* Left Side */}
        <div className="col-12 px-0 col-md-8 features__container">
          <p className="monterrey-about__title">THE BODY METHOD</p>
          <p className="monterrey-about__desc mb-0">
            {`Nuestra misión: Inspirar, educar y empoderar a la mujer a través del movimiento`}
          </p>
          <p className="monterrey-about__desc">
            Impactando su vida de manera positiva con un método fitness único
            respaldado por la ciencia.
          </p>

          <div className="row w-100 mx-auto">
            <div className="col-6 mb-md-5">
              <div className="row mb-5 monterrey-about__feature">
                <p className="feature__numb">+800</p>
                <p className="feature__name">MIEMBROS ACTIVOS</p>
              </div>
              <div className="row monterrey-about__feature">
                <p className="feature__numb">+700</p>
                <p className="feature__name">CERTIFICACIONES COMPLETADAS</p>
              </div>
            </div>

            <div className="col-6 mb-md-5">
              <div className="row mb-5 monterrey-about__feature">
                <p className="feature__numb">+500</p>
                <p className="feature__name">CLASES ONLINE GRATIS</p>
                <span className="feature__desc">
                  En la compra de cualquier de cualquier paquete presencial
                </span>
              </div>
              <div className="row monterrey-about__feature">
                <p className="feature__numb">+2100</p>
                <p className="feature__name">TRANSFORAMCIONES</p>
              </div>
            </div>
          </div>

          <div className="row w-100 mx-auto">
            <button
              type="button"
              className="btn monterrey-about__btn text-white bold fs-5 px-5 monterrey-degraded-btn"
              style={{ borderRadius: "40px", height: "50px" }}
              onClick={() => navigate(trialClassCheckoutRoute)}
            >
              COMIENZA YA
              <i className="fas fa-arrow-right ms-2" />
            </button>
          </div>
        </div>
        {/* Right Side */}
        <div className="col-4 hide-mobile ms-auto">
          <img
            alt="tbm members"
            src={"/img/monterrey-about.png"}
            className=""
          />
        </div>
      </div>
    </div>
  );
};

export default MonterreyLandingAbout;
