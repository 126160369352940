import React, { useEffect } from "react";
import { useContext } from "react";
import { MilestonesContext } from "../context/MilestonesContext";
import { CustomerMilestonesContext } from "../context/CustomerMilestonesContext";
import Accordion from "../components/global/Accordion";
import PanelTitle from "../components/global/PanelTitle";
import { ModalContext } from "../context/ModalContext";
import SingleMilestoneBenefit from "../components/milestones/SingleMilestone";

const MisNiveles = () => {
  const { customer_milestones, getCustomerMilestones } = useContext(
    CustomerMilestonesContext
  );
  const { milestones, getMilestones } = useContext(MilestonesContext);
  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getCustomerMilestones();
    getMilestones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showBenefit = (benefit) => {
    if (benefit.type === "video") {
      return modalComponent(
        benefit.name,
        <SingleMilestoneBenefit milestone_benefit={benefit} />
      );
    }
    window.open(benefit.src, "_blank");
  };

  const renderMilestones = () => {
    if (Array.isArray(milestones) && Array.isArray(customer_milestones)) {
      const achieved_milestones = customer_milestones.map(
        ({ milestone_id }) => milestone_id
      );
      return (
        <Accordion
          hideChevron
          items={milestones.map((milestone, index) => ({
            title: (
              <h3 className="mb-3 text-uppercase">
                Nivel {index + 1}{" "}
                {new Array(index + 1).fill(1).map((one, index) => (
                  <i key={index} className="fa fa-star" />
                ))}
              </h3>
            ),
            disabled: !achieved_milestones.includes(milestone.milestone_id),
            description: (
              <p className="small mb-0">
                <b className="text-uppercase me-2">{milestone.name}:</b>
                {milestone.description}
              </p>
            ),
            children: (
              <div>
                {milestone.milestone_benefits.length === 0 && (
                  <p className="mb-0">Próximamente</p>
                )}
                {milestone.milestone_benefits?.map((benefit) => (
                  <button
                    key={benefit.milestone_benefit_id}
                    onClick={() => showBenefit(benefit)}
                    className="btn me-2 btn-outline-primary"
                  >
                    <i
                      className={`fa fa-${
                        benefit.type === "file"
                          ? "file"
                          : benefit.type === "url"
                          ? "link"
                          : "video"
                      }`}
                    />{" "}
                    {benefit.name}
                  </button>
                ))}
              </div>
            ),
          }))}
        />
      );
    }
    return <div className="spinner-border" />;
  };

  return (
    <div className="container-fluid py-3 px-4">
      <PanelTitle title="Mis Niveles" /> {renderMilestones()}
    </div>
  );
};

export default MisNiveles;
