import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCreditCard from "./StripeCreditCard";
import useBranch from "../../hooks/useBranch";
import { CheckoutContext } from "../../context/CheckoutContext";

const StripeCheckout = ({ cart, element_id, metadata, discountCode, installmentsOptions }) => {
  const [publicKey, setPublicKey] = useState(null);

  const { class_package } = useContext(CheckoutContext);
  const { branch } = useBranch();

  useEffect(() => {
    setPublicKey(branch.stripe_public);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (class_package && class_package !== null) {
      if (class_package.branch_id !== branch.branch_id) {
        setPublicKey(class_package.branch.stripe_public);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, class_package]);

  return (
    <div className="container-fluid position-relative h-100 px-0">
      {publicKey !== null && (
        <Elements stripe={loadStripe(publicKey)}>
          <StripeCreditCard
            cart={cart}
            metadata={metadata}
            element_id={element_id}
            discountCode={discountCode}
            installmentsOptions={installmentsOptions}
          />
        </Elements>
      )}
    </div>
  );
};

export default StripeCheckout;
