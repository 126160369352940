import React, { useContext } from "react";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import ClassTypeCard from "./ClassTypeCard";

const ClassTypesGrid = ({ title }) => {
  const { class_types } = useContext(ClassTypeContext);

  const renderTitle = () => {
    if (title && title !== null) {
      return <h3>{title}</h3>;
    }
  };
  const renderClassTypes = () => {
    if (Array.isArray(class_types)) {
      let class_types_render = class_types.filter(
        (class_type) => class_type.file !== null
      );
      if (class_types_render.length > 0) {
        return class_types_render
          .filter(({ videos }) => videos?.length > 0)
          .map((class_type) => (
            <div key={class_type.class_type_id} className="col-6 col-md-3">
              <ClassTypeCard class_type={class_type} />
            </div>
          ));
      }
    }
  };
  return (
    <div>
      {renderTitle()}
      <div className="row mb-3">{renderClassTypes()}</div>
    </div>
  );
};

export default ClassTypesGrid;
