import React, { useContext } from "react";
import ClassReservationRating from "./ClassReservationRating";
import { ModalContext } from "../../context/ModalContext";
import moment from "moment";
import { canCancel } from "../../utils";
import useBranch from "../../hooks/useBranch";

const ReservationsCard = ({ reservation, handleCancel }) => {
  const { modalComponent } = useContext(ModalContext);
  const { branch } = useBranch();

  const handleRating = () => {
    modalComponent(
      "Calificar Clase",
      <ClassReservationRating
        handleCancel={handleCancel}
        class_reservation_id={reservation.class_reservation_id}
      />
    );
  };

  const renderInstructorName = (class_reservation) => {
    if (class_reservation.single_class !== null) {
      const instructors = class_reservation.single_class.class_instructors;
      if (instructors.length > 0) {
        if (instructors.length === 1) {
          return instructors[0].name;
        } else {
          return instructors.map(({ name }) => name).join(", ");
        }
      }
    }
  };

  const renderRating = () => {
    if (reservation.class_stars === null) {
      return (
        <button onClick={handleRating} className="btn btn-sm btn-outline-dark">
          <i className="fa fa-star"></i> Calificar
        </button>
      );
    }
    return (
      <span>
        {reservation.class_stars} <i className="fa fa-star text-warning"></i>
      </span>
    );
  };

  const renderClassType = () => {
    const { single_class } = reservation;
    if (single_class && single_class !== null) {
      const { class_type } = single_class;
      if (class_type && class_type !== null) {
        return class_type.name;
      }
    }
  };

  return (
    <div className="card px-0 py-0 no-scale mb-2">
      <div className="card-header">
        <div className="row align-items-center my-2">
          <div className="col-6">
            <h4 className="mb-0">{renderClassType()}</h4>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">
              {moment(reservation.single_class.class_date)
                .utc()
                .format("DD MMM YY, HH:mm")}
            </p>
          </div>
        </div>
      </div>
      <div className="card-body">
        <p>{renderInstructorName(reservation)}</p>
      </div>
      <div className="card-footer">
        {renderRating()}
        {canCancel(reservation.single_class, branch) &&
          reservation.deletedAt === null && (
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => handleCancel(reservation)}
            >
              <i className="fa fa-times"></i>
            </button>
          )}
      </div>
    </div>
  );
};

export default ReservationsCard;
