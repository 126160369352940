import { CUSTOMERS_RECIBIDOS, HIDE_SPINNER, SET_CUSTOMER, SHOW_SPINNER } from "../types";

const CustomerReducer = (state, { type, payload }) => {
  switch (type) {
    case CUSTOMERS_RECIBIDOS: {
      return { ...state, customers: payload };
    }
    case SET_CUSTOMER: {
      return { ...state, customer: payload };
    }
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};

export default CustomerReducer;
