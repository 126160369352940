import React from "react";
import LandingMonterrey from "./LandingMonterrey";

const HomeMonterrey = () => {
  return (
    <div className="container-fluid px-0">
      <LandingMonterrey />
    </div>
  );
};

export default HomeMonterrey;
