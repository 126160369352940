import React from "react";
import useBranch from "../../hooks/useBranch";

const WhatsApp = () => {
  const { branch } = useBranch();

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://wa.me/52${
        branch?.branch_id === 1 ? "8125159100" : "6634510006"
      }`}
      className="btn btn-success btn-whatsapp"
    >
      <div className="row align-items-center mx-0">
        <div className="col-2 px-0">
          <i className="fab fa-whatsapp"></i>
        </div>
        <div className="col-10 px-0">
          <span className="btn-whatsapp-text mx-2">Ayuda</span>
        </div>
      </div>
    </a>
  );
};
export default WhatsApp;
