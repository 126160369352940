import api from "./api";

const route = "/milestone_benefits";

const MilestoneBenefitsService = {
  getMilestone_benefits: () => api.get(route),
  getSingleMilestone_benefit: (milestone_benefit_id) =>
    api.get(`${route}/${milestone_benefit_id}`),
  postMilestone_benefit: (milestone_benefit) =>
    api.post(route, { ...milestone_benefit }),
  putMilestone_benefit: (milestone_benefit) =>
    api.put(route, { ...milestone_benefit }),
  deleteMilestone_benefit: (Milestone_benefit) =>
    api.delete(`${route}/${Milestone_benefit}`),
};

export default MilestoneBenefitsService;
