import React, { useContext } from "react";
import { S3_ENDPOINT } from "../../utils";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";
import VerifyWhatsApp from "../customers/VerifyWhatsApp";

const UserData = ({ user, handleEdit }) => {
  const { name, last_name, birthdate, instagram, phone, email } = user;
  const { modalComponent } = useContext(ModalContext);

  const handleWhatsAppVerify = () => {
    modalComponent("Verificar WhatsApp", <VerifyWhatsApp />);
  };

  const getSrc = () => {
    const { file } = user;
    let src = "icono-blanco.png";
    if (file && file !== null) {
      src = `${file.name}.${file.type}`;
    }
    return `${S3_ENDPOINT}/${src}`;
  };

  const renderBirthdate = () => {
    const birthday = moment(birthdate).utc();
    if (birthday.isValid()) {
      return birthday.format("DD MMM YYYY");
    }
    return <span className="text-danger">No has agregado tu compleaños</span>;
  };

  const renderVerifyWhatsApp = () => {
    if (user.whatsapp_verified)
      return (
        <span className="badge bg-success ms-2">
          <i className="fa fa-check-circle" /> Verificado
        </span>
      );

    return (
      <button
        onClick={handleWhatsAppVerify}
        className="btn btn-sm btn-link"
      >
        <i className="fa fa-check-circle" /> Verificar
      </button>
    );
  };

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12 col-md-2">
          <img src={getSrc()} alt="perfil" className="profile-image mb-4" />
        </div>
        <div className="col-12 col-md-10">
          <h4 className="border-bottom pb-2">
            {name} {last_name}
          </h4>
          <div className="small mb-1">
            <i className="fa fa-envelope me-3"></i>
            {email}
          </div>
          <div className="small mb-1">
            <i className="fa fa-birthday-cake me-3"></i>
            {renderBirthdate()}
          </div>
          <div className="small mb-1">
            <i className="fab fa-whatsapp me-3"></i>
            {phone}
            {renderVerifyWhatsApp()}
          </div>
          <div className="small mb-1">
            <i className="fab fa-instagram me-3"></i>
            {instagram}
          </div>
        </div>
      </div>
      {user.signup_reason === null && (
        <p className="text-danger small">
          Necesitas completar esta información. Haz click en editar información.
        </p>
      )}
      <button onClick={handleEdit} className="btn btn-sm mt-3 btn-primary">
        <i className="fa fa-edit me-3"></i>Editar Informacion
      </button>
    </div>
  );
};

export default UserData;
