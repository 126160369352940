import React, { createContext, useReducer, useContext } from "react";
import CustomerReducer from "../reducers/CustomerReducer";
import CustomerService from "../services/CustomerService";
import {
  CUSTOMERS_RECIBIDOS,
  HIDE_SPINNER,
  SET_CUSTOMER,
  SET_ERROR,
  SHOW_SPINNER,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";

const initialState = {
  customers: null,
  customer: null,
  error: "",
};

export const CustomerContext = createContext(initialState);

export const CustomerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerReducer, initialState);

  const { success, clearModal } = useContext(ModalContext);

  const getCustomersByQuery = (query) => {
    CustomerService.getCustomersByQuery(query).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getAllCustomers = (query) => {
    dispatch({ type: CUSTOMERS_RECIBIDOS, payload: null });
    CustomerService.getAllCustomers(query).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getCustomer = (customer_id) => {
    CustomerService.getCustomer(customer_id).then((res) => {
      dispatch({ type: SET_CUSTOMER, payload: res.data });
    });
  };

  const extenderAcceso = (
    customer_id,
    package_class_id,
    package_days,
    is_gift,
    payment_method_id,
    bill
  ) => {
    CustomerService.extenderAcceso(
      customer_id,
      package_class_id,
      package_days,
      is_gift,
      payment_method_id,
      bill
    ).then(() => {
      success("¡Acceso agregado!");
      getCustomer(customer_id);
      hideModal();
    });
  };

  const revokeAccess = (purchase_id, customer_id) => {
    CustomerService.revokeAccess(purchase_id).then((res) => {
      getCustomer(customer_id);
      success("¡Acceso eliminado!");
      hideModal();
    });
  };

  const removeClasses = (customer_id, amount) => {
    CustomerService.removeClasses(customer_id, amount).then(() => {
      getCustomer(customer_id);
      success("¡Clases Restadas!");
      hideModal();
    });
  };

  const clearCustomer = () => {
    dispatch({ type: SET_CUSTOMER, payload: null });
  };

  const requestCode = () => {
    CustomerService.postRequestWhatsAppCode().then();
  };

  const verifyCode = (code, callback) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: SET_ERROR, payload: "" });
    CustomerService.postVerifyWhatsAppCode(code)
      .then(() => {
        clearModal();
        success("WhatsApp verificado.")
        dispatch({ type: HIDE_SPINNER });
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch(() => {
        dispatch({ type: SET_ERROR, payload: "El código es incorrecto" });
        dispatch({ type: HIDE_SPINNER });
      });
  };

  return (
    <CustomerContext.Provider
      value={{
        ...state,
        getCustomer,
        getAllCustomers,
        getCustomersByQuery,
        extenderAcceso,
        clearCustomer,
        removeClasses,
        revokeAccess,
        requestCode,
        verifyCode,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
