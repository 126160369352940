import { navigate } from "@reach/router";
import moment from "moment";

export const daysArray = [1, 2, 3, 4, 5, 6, 7];

export const isDateBeforeStartOrAfterEnd = (
  currentDate,
  min_start_date,
  max_end_date
) => {
  return (
    (moment(min_start_date).isValid() &&
      moment(currentDate).isBefore(moment(min_start_date))) ||
    (moment(max_end_date).isValid() &&
      moment(currentDate).isAfter(moment(max_end_date)))
  );
};

export const getStartEndDate = (customer_workout_plan) => {
  let { start_date, end_date } = customer_workout_plan;
  start_date = moment(start_date).utc().format("YYYY-MM-DD");
  if (end_date === null) {
    end_date = moment(start_date).utc().add(1, "month").format("YYYY-MM-DD");
  }
  return { start_date, end_date };
};

export const handleWorkoutPlanner = (customer_workout_plan) => {
  if (customer_workout_plan && customer_workout_plan !== null) {
    return navigate(
      `/mytbm/workout-planner/${customer_workout_plan.customer_workout_plan_id}`
    );
  }
  navigate("/pages/workout-planner");
};

export const hasCompletedDay = (dateString, playbacks, reservations) => {
  if (Array.isArray(playbacks)) {
    const currentPlaybacks = playbacks.find(({ day }) => day === dateString);
    if (currentPlaybacks && currentPlaybacks !== null) {
      if (currentPlaybacks.playbacks > 0) return true;
    }
  }
  if (Array.isArray(reservations)) {
    const dateReservations = reservations.filter(
      ({ single_class }) =>
        moment(single_class.class_date).format("YYYY-MM-DD") === dateString
    );
    if (dateReservations.length > 0) {
      let attended = dateReservations.filter(({ attend }) => attend);
      return attended.length > 0;
    }
  }
};

export const getTarget = (name, frequency, weekIndex, dayIndex, week_days) => {
  if (!weekIndex) {
    weekIndex = moment().week() - moment().startOf("month").week();
  }
  if (!dayIndex) {
    dayIndex = moment().weekday();
  }
  if (name === "Holistic") {
    return "Full Body";
  }
  if (Array.isArray(week_days)) {
    dayIndex = week_days.indexOf(String(dayIndex));
  }
  if (frequency === 3) {
    if (weekIndex % 2 === 0) {
      return dayIndex % 3 === 0 ? "Lower" : "Upper";
    }
    return dayIndex % 3 === 0 ? "Upper" : "Lower";
  } else {
    if (weekIndex % 2 === 0) {
      return dayIndex % 2 === 0 ? "Lower" : "Upper";
    }
    return dayIndex % 2 === 0 ? "Upper" : "Lower";
  }
};

export const getClassCategoryDay = (
  workout_plan,
  customer_workout_plan,
  dayIndex
) => {
  if (!dayIndex) dayIndex = moment().weekday();
  if (customer_workout_plan && customer_workout_plan !== null) {
    if (
      customer_workout_plan.workout_plan_id === workout_plan.workout_plan_id
    ) {
      if (
        customer_workout_plan.week_days &&
        customer_workout_plan.week_days !== null
      ) {
        const week_days = String(customer_workout_plan.week_days)
          .split(",")
          .map((day) => parseInt(day))
          .sort((a, b) => (a < b ? -1 : 1));
        const planner_days = workout_plan.workout_plan_days
          .sort((a, b) => (a.day < b.day ? -1 : 1))
          .map((day, index) => ({ ...day, day: week_days[index] }));
        return planner_days.find((day) => day.day === dayIndex);
      }
    }
  }
  const days = workout_plan.workout_plan_days.sort((a, b) =>
    a.day < b.day ? -1 : 1
  );
  let hasDay = days.find((day) => day.day === dayIndex);
  return hasDay;
};
