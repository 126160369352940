
import api from './api';

const route = '/customer_milestones';

const CustomerMilestones = {
  getCustomerMilestones: () => api.get(route),
  getSingleCustomerMilestone: (CustomerMilestone_id) => api.get(`${route}/${CustomerMilestone_id}`),
  postCustomerMilestone: (CustomerMilestone) => api.post(route, { ...CustomerMilestone}),
  putCustomerMilestone: (CustomerMilestone) => api.put(route, { ...CustomerMilestone}),
  deleteCustomerMilestone: (CustomerMilestone) => api.delete(`${route}/${CustomerMilestone}`),
};

export default CustomerMilestones;