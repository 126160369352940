import React, { useContext, useEffect } from "react";
import { Link } from "@reach/router";
import { FrequentQuestionsContext } from "../../context/FrequentQuestionsContext";
import Accordion from "./Accordion";
import Page from "./Page";

const PreguntasFrecuentes = () => {
  const { allQuestions, getFrequentQuestions } = useContext(FrequentQuestionsContext);

  useEffect(() => {
    getFrequentQuestions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFrequentQuestions = () => {
    if(allQuestions?.length > 0) {
      const items = allQuestions.map(obj => {
        return {
          title: obj.question,
          htmlContent: obj.response
        }
      });

      return(
        <Accordion
          id={'frequent_questions'}
          items={items}
        />
      )
    }
  }

  return (
    <Page 
      verticalAlign={'align-items-start'}
      title="Preguntas Frecuentes"
    >
      {renderFrequentQuestions()}
     
      <p>
        Dale click <Link to="/checkout/19271">aquí</Link> para registrarte en
        nuestra prueba gratuita de 7 días.
      </p>
    </Page>
  );
};

export default PreguntasFrecuentes;
