import React from "react";
import { getValue } from "../../utils";

const VideoInfo = ({ video }) => {
  const renderProps = () => {
    if (Array.isArray(video.video_props)) {
      if (video.video_props.length === 0) {
        return <span className="small">{video.description}</span>;
      }
      return video.video_props.map((video_prop) => (
        <span
          key={video_prop.prop_id}
          className="badge badge-pill bg-primary me-2 mb-2"
        >
          <span className="d-block">
            {video_prop.amount > 1 ? <span>  {"("}
            {video_prop.amount}
            {")"} </span>: "" }
           {video_prop.prop?.name}
          </span>
          <span className="d-block mt-2">
            {getValue(video_prop, "required", "boolean")
              ? "* Requerido"
              : "(opcional)"}
          </span>
        </span>
      ));
    }
  };

  return (
    <div className="card bg-light br-0 shadow no-scale">
      <p className="bold mb-2">Props</p>
      <p className="mb-0">{renderProps()}</p>
    </div>
  );
};
export default VideoInfo;
