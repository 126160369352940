import React, { useEffect, useContext } from "react";
import { VideosContext } from "../../context/VideosContext";
import ProgramCard from "../programs/ProgramCard";
import ItemCarousel from "../global/ItemCarousel";

const LandingPrograms = () => {
  const { programs, getPrograms } = useContext(VideosContext);

  useEffect(() => {
    getPrograms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const renderProgramas = () => {
    if (Array.isArray(programs)) {
      return programs.map((program) => (
        <ProgramCard key={program.program_id} program={program} />
      ));
    }
  };

  return (
    <div className="container-fluid text-center px-5 py-5 bg-light">
      <h2 className="font-header-secondary">Programas</h2>
      <p className="mb-5">
        Conoce nuestro método una combinación de Cardio + Fuerza + Barre y
        entrena inteligente!
      </p>
      <div className="mw-1600 m-auto my-3 mb-5 hide-mobile">
        <div className="row mb-4">{renderProgramas()}</div>
      </div>
      <div className="show-mobile">
        <ItemCarousel
          id="programs"
          wide
          dark
          automatic
          items={renderProgramas()}
        />
      </div>
      <p className="small text-muted mt-md-0 mt-4">
        *Cualquier membresía incluye acceso a todos nuestros programas
      </p>
    </div>
  );
};

export default LandingPrograms;
