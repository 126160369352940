import React, { useContext, useEffect, useState } from "react";
import { PaymentSourcesContext } from "../../context/PaymentSourcesContext";
import PaymentMethodCard from "../payment_sources/PaymentMethodCard";
import { CheckoutContext } from "../../context/CheckoutContext";
import PaymentSource from "../payment_sources/PaymentSource";
import CheckoutService from "../../services/CheckoutService";
import { handleCheckoutError } from "../../utils/checkout";
import { ModalContext } from "../../context/ModalContext";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";
import StripeCheckout from "../common/StripeCheckout";
import SubmitButton from "../common/SubmitButton";
import useBranch from "../../hooks/useBranch";
import { navigate } from "@reach/router";

const installmentProducts = [
  {
    name: "16 clases semestral",
    class_package_id: 19255,
  },
  {
    name: "12 clases semestral",
    class_package_id: 19253,
  },
  {
    name: "Ilimitado semestral",
    class_package_id: 19251,
  },
  {
    name: "12 clases anual",
    class_package_id: 19265,
  },
  {
    name: "16 clases anual",
    class_package_id: 19267,
  },
  {
    name: "Ilimitado anual",
    class_package_id: 19264,
  },
];

const CheckoutPaymentMethods = ({ metadata, class_package_id }) => {
  const [paymentPlan, setPaymentPlan] = useState("");
  const [processing, setProcessing] = useState(false);

  const { branch } = useBranch();
  const { user } = useContext(AuthContext);
  const { alert } = useContext(ModalContext);
  const { payment_sources, getPaymentSources } = useContext(
    PaymentSourcesContext
  );

  const {
    descuento,
    setPayPal,
    discountCode,
    class_package,
    payment_source,
    setPaymentSource,
    setPayPalSubscription,
  } = useContext(CheckoutContext);
  const { cart } = useContext(CartContext);

  useEffect(() => {
    if (user !== null) {
      getPaymentSources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const paypalButton = document.getElementById("paypal-button");
    if (
      user !== null &&
      class_package !== null &&
      paypalButton !== null &&
      payment_source === "paypal"
    ) {
      if (class_package.is_subscription) {
        const payload = {
          cart,
          branch,
          metadata,
          class_package_id,
          discountCode,
        };
        if (
          class_package.branch_id !== branch.branch_id &&
          class_package.branch !== null
        ) {
          payload.branch = class_package.branch;
        }
        setPayPalSubscription(payload);
      } else {
        setPayPal(class_package_id, discountCode, cart, metadata);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, descuento, metadata, class_package, payment_source]);

  const renderPaymentSources = () => {
    if (Array.isArray(payment_sources)) {
      if (
        !installmentOption ||
        (installmentOption && paymentPlan === "subscription")
      )
        return payment_sources
          .filter((metodo) => metodo.source_id !== null)
          .map((metodo) => (
            <PaymentSource
              payment_source={metodo}
              paymentMethod={payment_source}
              key={metodo.payment_source_id}
              setPaymentSource={setPaymentSource}
            />
          ));
    }
  };

  const renderPayPal = () => {
    if (
      (installmentOption && paymentPlan === "subscription") ||
      !installmentOption
    )
      return (
        <PaymentMethodCard
          name="paypal"
          label="PayPal"
          selected={payment_source}
          setPaymentMethod={setPaymentSource}
        >
          <div
            id="paypal-button"
            style={{
              visibility: payment_source === "paypal" ? "visible" : "hidden",
            }}
          ></div>
        </PaymentMethodCard>
      );
  };

  const handleError = (message) => {
    setProcessing(false);
    alert(message);
  };

  const handleSuccess = (purchase_id) => {
    setProcessing(false);
    navigate(`/gracias/${purchase_id}`);
  };

  const handleSubmit = () => {
    setProcessing(true);
    metadata.payment_plan = paymentPlan;
    CheckoutService.postCheckout(
      class_package_id,
      discountCode,
      payment_source,
      cart,
      metadata
    )
      .then(async (res) => {
        const { session, purchase_id } = res.data;
        if (session && session !== null) {
          return (window.location = session.url);
        }
        handleSuccess(purchase_id);
      })
      .catch((error) => handleCheckoutError(error, handleError));
  };

  const renderPayButton = () => {
    if (
      !["card", "paypal"].includes(payment_source) ||
      (installmentOption && paymentPlan === "installments")
    ) {
      return (
        <div className="container-fluid px-0 text-right">
          <SubmitButton
            label={installmentOption && paymentPlan === "installments" ? "Continuar": "Pagar Ahora"}
            spinner={processing}
            onClick={handleSubmit}
          />
          {installmentOption && paymentPlan === "installments" && (
            <p className="mt-3 text-start small">
              En el siguiente paso podrás elegir el Plan de Meses Sin Intereses
              que prefieras. Al hacer click aún no se te hará ningún cobro.
            </p>
          )}
        </div>
      );
    }
  };

  const renderInstallments = () => {
    if (installmentOption) {
      return (
        <div className="card p-3 no-scale shadow mb-3">
          <h5 className="mb-2">Plan de Pagos</h5>
          <label className="mb-3 d-block">
            <input
              type="radio"
              name="payment_plan"
              value="subscription"
              checked={paymentPlan === "subscription"}
              onChange={(e) => {
                if (e.target.checked) setPaymentPlan("subscription");
              }}
            />{" "}
            <b>Cargo Recurrente:</b> congela el precio de oferta hasta que
            canceles. Aplica unicamente en pago de contado.
          </label>
          <label className="mb-3 d-block">
            <input
              type="radio"
              name="payment_plan"
              value="installments"
              checked={paymentPlan === "installments"}
              onChange={(e) => {
                if (e.target.checked) setPaymentPlan("installments");
              }}
            />{" "}
            Meses Sin Intereses (Elige 3 MSI o 6 MSI).
          </label>
        </div>
      );
    }
  };

  const renderPago = () => {
    if (user !== null) {
      if (class_package && class_package !== null) {
        if (parseFloat(class_package.price) === 0.0) {
          return (
            <div className="container-fluid px-0 text-right">
              <SubmitButton
                label="Obtener Ahora"
                spinner={processing}
                onClick={handleSubmit}
              />
            </div>
          );
        }
      }

      const canPayWithCard = !installmentOption || (installmentOption && paymentPlan === "subscription");

      return (
        <div className="oveflow-hidden">
          <h3>Forma de Pago</h3>
          {renderInstallments()}
          {renderPaymentSources()}
          {canPayWithCard && (
              <PaymentMethodCard
                name="card"
                label="Tarjeta de Crédito/Débito"
                selected={payment_source}
                setPaymentMethod={setPaymentSource}
              >
                {payment_source === "card" && (
                  <StripeCheckout
                    metadata={metadata}
                    discountCode={discountCode}
                    element_id={class_package_id}
                  />
                )}
              </PaymentMethodCard>
            )}
          {renderPayPal()}
          {renderPayButton()}
        </div>
      );
    }
  };

  const installmentOption = installmentProducts.find(
    (current_package) =>
      current_package.class_package_id === parseInt(class_package_id)
  );

  return <div>{renderPago()}</div>;
};

export default CheckoutPaymentMethods;
