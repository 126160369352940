import { Link } from "@reach/router";
import React from "react";

const CheckoutHeader = () => {
  return (
    <div className="row border-bottom align-items-center bg-light pb-3 pt-3 mx-0">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-8 col-md-10">
            <h1 className="mb-0">Checkout</h1>
          </div>
          <div className="col-4 col-md-2 text-end">
            <Link to="/">
              <img
                src="https://thebodymethod.mx/img/logo.png"
                className="logo-navbar"
                alt="logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;
