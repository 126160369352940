import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { SingleClassContext } from "../context/SingleClassContext";

const useSchedule = () => {
  // eslint-disable-next-line no-unused-vars
  const [layout, setLayout] = useState("week");
  // const [week, setWeek] = useSchedule(initialMoment.week());
  const [year, setYear] = useState(null);

  const {
    days,
    month,
    update,
    setMonth,
    end_date,
    start_date,
    setEndDate,
    getSchedule,
    setStartDate,
    clearSemanas,
  } = useContext(SingleClassContext);

  const getStartDate = (layout) => {
    const momentObject =
      month !== null && month !== moment().month()
        ? moment(month + 1, "M")
        : moment();
    return momentObject
      .startOf(layout === "week" ? "isoWeek" : "month")
      .format("YYYY-MM-DD");
  };

  const getEndDate = (layout, start_date) => {
    return moment(start_date)
      .add(1, layout === "week" ? "isoWeek" : "month")
      .format("YYYY-MM-DD");
  };

  const validDates = (start_date, end_date) => {
    return (
      moment(start_date).isValid() &&
      moment(end_date).isValid() &&
      moment(end_date).isAfter(moment(start_date))
    );
  };

  useEffect(() => {
    const initialMoment = moment();
    const currentMonth = initialMoment.month();
    const currentYear = initialMoment.year();
    setMonth(currentMonth);
    setYear(currentYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (month !== null) {
      const startDate = getStartDate(layout);
      setStartDate(startDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, layout]);

  useEffect(() => {
    const endDate = getEndDate(layout, start_date);
    setEndDate(endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date]);

  useEffect(() => {
    clearSemanas();
    fetchSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_date, update]);

  const fetchSchedule = () => {
    let startDate = start_date;
    if(!startDate) {
      startDate = getStartDate(layout);
      setStartDate(startDate);
    }
    if (validDates(startDate, end_date)) {
      getSchedule({
        start_date: startDate,
        end_date,
      });
    }
  };

  const setNextMonth = () => {
    const currentMonth = moment(month + 1, "M");
    const newMonth = currentMonth.add(1, "month").month();
    if (month === 11) {
      const currentYear = moment(year, "YYYY");
      const newYear = currentYear.add(1, "year").year();
      setYear(newYear);
    }
    setMonth(newMonth);
  };

  const setPrevMonth = () => {
    const currentMonth = moment(month + 1, "M");
    const newMonth = currentMonth.subtract(1, "month").month();
    if (month === 0) {
      const currentYear = moment(year, "YYYY");
      const newYear = currentYear.subtract(1, "year").year();
      setYear(newYear);
    }
    setMonth(newMonth);
  };

  const getStartNext = (layout, start_date) => {
    return moment(start_date)
      .add(1, layout)
      .startOf(layout === "week" ? "isoWeek" : "month")
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
  };

  const getStartPrev = (layout, start_date) => {
    return moment(start_date)
      .subtract(1, layout)
      .startOf(layout === "week" ? "isoWeek" : "month")
      .format("YYYY-MM-DD");
  };

  const handleNextWeek = () => {
    const next_week = getStartNext("week", start_date);
    const nextWeekStartMonth = moment(next_week).month();
    if (nextWeekStartMonth > month) {
      return setMonth(month + 1);
    }
    setStartDate(next_week);
  };

  const handlePrevWeek = () => {
    const prev_week = getStartPrev("week", start_date);
    const prevWeekStartMonth = moment(prev_week).month();
    if (prevWeekStartMonth < month) {
      setMonth(month - 1);
    }
    setStartDate(prev_week);
  };

  const monthString = moment(month + 1, "M").format("MMM");
  const yearString = moment(year, "YYYY").format("YYYY");

  const getWeekNumber = () => {
    if (month !== null) {
      if (month !== moment().month()) {
        const weekStart = moment(start_date).startOf("isoWeek").week();
        const monthWeekStart = moment(month + 1, "M")
          .startOf("month")
          .startOf("isoWeek")
          .week();
        return weekStart - monthWeekStart + 1;
      }
      const weekStart = moment(start_date).week();
      const monthWeekStart = moment(month + 1, "M")
        .startOf("month")
        .week();
      return weekStart - monthWeekStart + 1;
    }
  };

  return {
    days,
    month,
    end_date,
    start_date,
    getEndDate,
    yearString,
    monthString,
    getStartDate,
    setNextMonth,
    setPrevMonth,
    getWeekNumber,
    handleNextWeek,
    handlePrevWeek,
  };
};

export default useSchedule;
