import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const LandingNavbar = () => {
  const { user } = useContext(AuthContext);

  return (
    <nav
      id="monterrey-landing"
      className="navbar navbar-expand-lg monterrey-navbar navbar-light w-100 hide-mobile"
    >
      <div className="container-fluid px-0 navbar-container">
        <a className="navbar-brand p-2 ps-0" href="/#landing">
          <img src="/logo512.png" style={{ width: "73px", height: "73px" }} />
        </a>
        <button
          className="navbar-toggler rounded-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse mw-100" id="navbarNav">
          <div className="container-fluid px-1 px-md-3 pe-md-5">
            <ul
              className="navbar-nav nav justify-content-center position-relative"
              style={{ paddingRight: "130px" }}
            >
              <li className={`nav-item nav-item-active`}>
                <a className="nav-link" href="#scrollspyHero">
                  Home
                </a>
              </li>
              <li className={`nav-item `}>
                <a className="nav-link" href="#scrollspyAbout">
                  Sobre Nosotros
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#scrollspyMtyClassTypes">
                  Método
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#scrollspyMtyCoaches">
                  Coaches
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#scrollspyMtyPackages">
                  Paquetes
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#scrollspyMtyLocations">
                  Studio
                </a>
              </li>
              <li className="navbar-cta__container ms-md-3 position-absolute end-0 bottom-0 top-0">
                <Link
                  className="btn bold navbar-cta text-uppercase"
                  to="/entrar"
                >
                  <i className="fa fa-user"></i>{" "}
                  <span className="ms-1">Ingresar</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default LandingNavbar;
