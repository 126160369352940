import React, { useState } from "react";
import parse from "html-react-parser";
import CustomIcon from "./CustomIcon";

const AccordionItem = ({
  id,
  icon,
  title,
  index,
  content,
  modifier,
  children,
  parentId,
  disabled,
  collapsed,
  controlled,
  description,
  hideChevron,
  setCollapsed,
}) => {
  const headerId = `head-${id}`;

  const renderContent = () => {
    if (content && content !== null && content !== "") {
      return parse(content);
    }
    if (children && collapsed === id) {
      return children;
    }
  };

  return (
    <div className="accordion-item text-start bg-light border shadow-sm mb-2" style={{ opacity: disabled ? 0.5 : 1}}>
      <div
        className="row mx-0 py-3 cursor-pointer"
        onClick={() => {
          if (disabled) return;
          if (collapsed === id) return setCollapsed(null);
          setCollapsed(id);
        }}
      >
        <div className="col-10 ps-3">
          <span
            className="boldaccordion-header h6 align-items-center mb-0"
            style={{
              fontFamily: 'Futura Condensed Extra Bold", sans-serif !important',
            }}
            id={headerId}
          >
            {icon && icon !== null && icon !== "" && (
              <div className="d-inline-block me-3">
                <CustomIcon icon={icon} />
              </div>
            )}
            {title}
          </span>
          {description}
        </div>
        <div className="col-2 pe-3 text-end">
          {!hideChevron && <i className={`fa fa-chevron-${collapsed ? "up" : "down"}`} />}
        </div>
      </div>
      <div
        id={id}
        className={`accordion-collapse ${collapsed !== id ? "collapse" : ""}`}
        aria-labelledby={headerId}
        data-bs-parent={parentId}
      >
        <div className="accordion-body large text-dark text-left">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ id, icon, items, hideChevron }) => {
  const [collapsed, setCollapsed] = useState(null);
  const renderItems = () => {
    if (Array.isArray(items)) {
      return items.map(({ title, htmlContent, children, disabled, description }, index) => (
        <AccordionItem
          key={index}
          icon={icon}
          index={index}
          title={title}
          parentId={id}
          disabled={disabled}
          content={htmlContent}
          collapsed={collapsed}
          id={`faq-${index + 1}`}
          hideChevron={hideChevron}
          description={description}
          setCollapsed={setCollapsed}
        >
          {children}
        </AccordionItem>
      ));
    }
  };

  return (
    <div className="accordion accordion-flush" id={id}>
      {renderItems()}
    </div>
  );
};

export default Accordion;
