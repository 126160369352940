import React, { useContext, useEffect } from "react";
import "./MonterreyLandingPackages.css";
import "./MonterreyLandingBanner.css";
import useWindowSize from "../../hooks/useWindowSize";
import { useNavigate } from "@reach/router";
import { trialClassCheckoutRoute } from "../../utils";

const MonterreyLandingBanner = () => {
  const navigate = useNavigate();

  const smallDevice = useWindowSize(767);
  const bannerSrc = smallDevice
    ? '/img/monterrey-banner-mobile.png'
    : '/img/monterrey-banner.png'

  return (
    <div className="container-fluid monterrey-banner bg-white position-relative">
      <img
        className=""
        alt="monterrey banner"
        src={bannerSrc}
      />
      <h3 className="monterrey-banner__title">JOIN THE CLUB</h3>
      <button
        type="button"
        className="btn position-absolute monterrey-banner__cta text-white bold px-4 px-md-5 monterrey-degraded-btn mt-auto"
        onClick={() => navigate(trialClassCheckoutRoute)}
        style={{
          borderRadius: "40px",
          height: "50px",
        }}
      >
        COMIENZA YA
        <i className="fas fa-arrow-right ms-2" />
      </button>
    </div>
  );
};

export default MonterreyLandingBanner;
