import React, { useContext, useEffect, useState } from "react";
import { PurchasesContext } from "../context/PurchasesContext";
import StatusBadge from "../components/global/StatusBadge";
import { AuthContext } from "../context/AuthContext";
import useBranch from "../hooks/useBranch";
import { formatMonto } from "../utils";
import { Link } from "@reach/router";
import moment from "moment";
import { CheckoutContext } from "../context/CheckoutContext";
import { AppConfigContext } from "../context/AppConfigContext";

const Gracias = ({ purchase_id }) => {
  const { purchase, getPurchase } = useContext(PurchasesContext);
  const [currentInterval, setCurrentInterval] = useState(null);
  const { user, getUsuario } = useContext(AuthContext);
  const { S3_ENDPOINT } = useContext(AppConfigContext);
  const { setDescuento } = useContext(CheckoutContext);
  const { selectBranch } = useBranch();

  useEffect(() => {
    return () => {
      getUsuario();
      window.clearInterval(currentInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPurchase(purchase_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase_id]);

  useEffect(() => {
    if (user !== null && purchase === null) {
      getPurchase(purchase_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (purchase !== null) {
      if (purchase.status === "pending" && currentInterval === null) {
        let interval = window.setInterval(() => {
          getPurchase(purchase_id);
        }, 3000);
        setCurrentInterval(interval);
      } else if (
        ["active", "cancelled", "failed"].includes(purchase.status) &&
        currentInterval !== null
      ) {
        window.clearInterval(currentInterval);
        setDescuento(null);
      }
      selectBranch(purchase.branch_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase]);

  const getLink = () => {
    if (purchase && purchase !== null) {
      if (purchase.class_amount > 0) {
        return "/mytbm/presencial";
      }
      return "/mybtm/online";
    }
    return "/mytbm";
  };

  const renderTotal = () => {
    if (purchase.class_package !== null) {
      if (purchase.class_package.free_trial_length) {
        return (
          <div>
            <p>Total Pagado: $0 MXN</p>
            <p>
              Siguiente Cargo: ${formatMonto(purchase.class_package.price)} MXN
              el{" "}
              {moment()
                .add(purchase.class_package.free_trial_length, "days")
                .format("DD MMM YYYY")}
            </p>
          </div>
        );
      }
      let total = purchase.total_payment;
      total = parseFloat(total).toFixed(2);
      return <p>Total: ${formatMonto(total)} MXN</p>;
    }
  };

  const renderCancelReason = () => {
    if (purchase.cancel_reason !== null) {
      return <p className="text-danger">{purchase.cancel_reason}</p>;
    }
  };

  const renderThankYouImage = () => {
    if (purchase && purchase !== null) {
      const { class_package } = purchase;
      if (class_package && class_package !== null) {
        const { receipt_file } = class_package;
        if (receipt_file && receipt_file !== null) {
          return <img src={`${S3_ENDPOINT}/${receipt_file.name}.${receipt_file.type}`} alt="recibo" className="mw-500 m-auto d-block mb-3" />;
        }
      }
    }
  };

  const renderOrden = () => {
    if (purchase && purchase !== null) {
      return (
        <div>
          <div className="mb-4">
            <h3>{purchase.title}</h3>
            <p>
              Fecha: {moment(purchase.created_at).format("DD MMM YYYY HH:mm")}
            </p>
            {renderTotal()}
          </div>
          <StatusBadge status={purchase.status} />
          {renderCancelReason()}
          <h4 className="border-bottom mt-4 pb-2">Detalles</h4>
          <p>Clases Compradas: {purchase.class_amount}</p>
          <p>Método de Pago: {purchase.payment_method.name}</p>
          <p>Cuenta: {user.email}</p>
          <p>Tus Beneficios renuevan cada {purchase.expiration_days} días.</p>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderButtonTexT = () => {
    if (purchase && purchase !== null) {
      if (purchase.class_amount > 0) {
        return "Reservar";
      }
      if (purchase.include_online) {
        return "TBM Online";
      }
    }
    return "TBM";
  };

  return (
    <div className="container-fluid bg-light vh-100 pt-5">
      <div className="container py-5">
        <h1 className="text-center m-4">Resumen</h1>
        {renderThankYouImage()}
        <div className="card no-scale mw-500 m-auto d-block p-4">
          {renderOrden()}
          <Link to={getLink()} className="btn btn-primary bold d-block">
            Ir a {renderButtonTexT()}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Gracias;
