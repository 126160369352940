import { getArgs } from "../utils";
import api from "./api";

const route = "/class_packages";

const PaquetesService = {
  getPaquetes: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getPaquete: (class_package_id) => api.get(`${route}/${class_package_id}`),
  getOnline: () => api.get(`${route}/online/all`),
  getAllPaquetes: () => api.get(`${route}/admin/all`),
  getOnlineHome: () => api.get(`${route}/online/home`),
  getPresenciales: () => api.get(`${route}/presenciales/all`),
  getPresencialesHome: () => api.get(`${route}/presenciales/home`),
  getEventosEspecialesHome: () => api.get(`${route}/especiales/home`),
  getPaquetesEspeciales: () => api.get(`${route}/especiales/all`),
};

export default PaquetesService;
