import React, { useContext, useEffect, useState } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import useBranch from "../../hooks/useBranch";
import MonterreyCoachCard from "../coaches/MonterreyCoachCard";
import "./MonterreyLandingCoaches.css"

const MonterreyLandingCoaches = () => {
  const { branch } = useBranch();
  const [coachId, setCoachId] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentCoaches, setCurrentCoaches] = useState(null);
  const { coaches, getHomeCoaches } = useContext(CoachesContext);

  useEffect(() => {
    getHomeCoaches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  useEffect(() => {
    if (Array.isArray(coaches)) {
      setCurrentCoaches(coaches);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coaches]);

  useEffect(() => {
    handleFounderCoach();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCoaches, coachId]);

  const handleFounderCoach = () => {
    if (
      Array.isArray(currentCoaches) &&
      window.innerWidth < 767 &&
      firstLoad &&
      coachId !== 2
    ) {
      setFirstLoad(false);
      handleNext();
    }
  };

  const handlePrev = () => {
    let current = [...currentCoaches];
    let last = current.pop();
    setCoachId(last.instructor_id);
    setCurrentCoaches([last, ...current]);
  };

  const handleNext = () => {
    let current = [...currentCoaches];
    let first = current.shift();
    setCoachId(first.instructor_id);
    setCurrentCoaches([...current, first]);
  };

  const renderCoaches = () => {
    if (Array.isArray(currentCoaches)) {
      return currentCoaches.map((coach) => {
        const index = coaches.findIndex(
          (instructor) => instructor.instructor_id === coach.instructor_id
        );
        return (
          <MonterreyCoachCard
            key={coach.instructor_id}
            coach={coach}
            color={
              index % 3 === 0
                ? "light"
                : index % 2 === 0
                ? "primary-light"
                : "primary"
            }
          />
        );
      });
    }
  };

  return (
    <div id="scrollspyMtyCoaches" className="container-fluid py-5">
      <h2 className="text-center mb-2 display-5 text-black">NUESTRAS COACHES</h2>
      <p className="text-center text-black">
        Coaches certificadas & expertas en ayudarte a alcanzar tus metas
      </p>
      
      <div className="monterrey-coaches-wrapper">
        <div className="row coaches-row monterrey__coaches-row align-items-center py-3">
          {renderCoaches()}
        </div>
      </div>

      <div className="container-fluid text-center">
        <button
          onClick={handlePrev}
          className="btn btn-light btn-round mx-1 shadow"
        >
          <i className="fa fa-chevron-left" />
        </button>
        <button
          onClick={handleNext}
          className="btn btn-light btn-round mx-1 shadow"
        >
          <i className="fa fa-chevron-right" />
        </button>
      </div>
    </div>
  );
};

export default MonterreyLandingCoaches;
