import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/global/Footer";
import { CartContext } from "../context/CartContext";
import { AuthContext } from "../context/AuthContext";
import { CheckoutContext } from "../context/CheckoutContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import CheckoutClassPackage from "../components/checkout/CheckoutClassPackage";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";
import CheckoutBillingDisclaimer from "../components/checkout/CheckoutBillingDisclaimer";
import { getValue } from "../utils";
import { parse } from "query-string";
import useBranch from "../hooks/useBranch";

const Checkout = ({ class_package_id }) => {
  //Checkout
  const { setDescuento, class_package, payment_source_id } =
    useContext(CheckoutContext);
  const { user } = useContext(AuthContext);
  const { cart_items, setCart, getCart } = useContext(CartContext);
  const [metadata, setMetadata] = useState({});
  const { branch, branches } = useBranch();

  useEffect(() => {
    const email = window.localStorage.getItem("email");
    if (email && email !== "" && email !== null) {
      getCart(email);
    }
    return () => {
      setDescuento(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDescuento(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_package_id]);

  useEffect(() => {
    if (class_package && class_package !== null) {
      handleBranchMetadata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, class_package]);

  useEffect(() => {
    if (user !== null) {
      getCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (Array.isArray(cart_items)) {
      let items = [...cart_items].filter(
        (item) => String(item.class_package_id) === String(class_package_id)
      );
      if (items.length > 0) setCart(items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart_items]);

  const handleBranchMetadata = () => {
    let branch_id = branch.branch_id;
    const searchParams = parse(window.location.search);
    if (searchParams.branch_id) {
      branch_id = searchParams.branch_id;
    } else if (searchParams.branch) {
      const branchData = branches.find(
        ({ slug }) => slug === searchParams.branch
      );
      if (branchData !== undefined) {
        branch_id = branchData.branch_id;
      }
    } else {
      const localBranch = window.localStorage.getItem("branch_id");
      if (localBranch && localBranch !== null) {
        branch_id = localBranch;
      }
    }

    if(branch_id !== branch.branch_id || branch_id !== class_package.branch_id) {
      setMetadata({ branch_id });
    }
  };

  const renderBillingDisclaimer = () => {
    if (getValue(class_package, "is_subscription")) {
      return <CheckoutBillingDisclaimer />;
    }
  };

  return (
    <div className="container-fluid px-0">
      <CheckoutHeader />
      <div className="container-fluid">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-6 col-xl-4 my-2">
            <CheckoutClassPackage class_package_id={class_package_id} />
            <CheckoutDiscount class_package_id={class_package_id} />
          </div>
          <div className="col-12 col-md-6 col-xl-8 my-2">
            <CheckoutAccount />
            <CheckoutPaymentMethods
              class_package_id={class_package_id}
              paymentMethod={payment_source_id}
              metadata={metadata}
            />
          </div>
        </div>
        {renderBillingDisclaimer()}
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
