import { useContext } from "react";
import useBranch from "./useBranch";
import { AuthContext } from "../context/AuthContext";
import CustomerFreeTrial from "../components/customers/CustomerFreeTrial";
import { ModalContext } from "../context/ModalContext";
import { useNavigate } from "@reach/router";

const useFreeTrial = () => {
  const navigate = useNavigate();

  const { branch } = useBranch();
  const { user } = useContext(AuthContext);
  const { modalComponent } = useContext(ModalContext);

  const handleRedirect = () => {
    navigate(
      `/checkout/19271/${branch?.branch_id === 2 ? "?branch_id=2" : ""}`
    );
  };

  const handleFreeTrial = () => {
    if (user === null) {
      return modalComponent(
        "Comienza Gratis",
        <CustomerFreeTrial handleCallback={handleRedirect} />
      );
    }
    handleRedirect();
  };

  return { handleFreeTrial };
};

export default useFreeTrial;
