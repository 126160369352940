import React, { createContext, useReducer, useContext } from "react";
import MilestoneBenefitsService from "../services/MilestoneBenefitsService";
import MilestoneBenefitsReducer from "../reducers/MilestoneBenefitsReducer";
import {
  MILESTONE_BENEFITS_RECEIVED,
  SET_MILESTONE_BENEFIT,
  CREATE_MILESTONE_BENEFIT,
  SET_PROPERTY_MILESTONE_BENEFIT,
} from "../types/milestone_benefits";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  milestone_benefits: null,
  milestone_benefit: null,
};

export const MilestoneBenefitsContext = createContext(initialState);

export const MilestoneBenefitsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MilestoneBenefitsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getMilestoneBenefits = () => {
    MilestoneBenefitsService.getMilestoneBenefits()
      .then((response) => {
        const { milestone_benefits } = response.data;
        dispatch({
          type: MILESTONE_BENEFITS_RECEIVED,
          payload: milestone_benefits,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleMilestone_benefit = (milestone_benefit_id) => {
    MilestoneBenefitsService.getSingleMilestone_benefit(milestone_benefit_id)
      .then((response) => {
        const { milestone_benefit } = response.data;
        dispatch({ type: SET_MILESTONE_BENEFIT, payload: milestone_benefit });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setMilestone_benefit = (milestone_benefit) => {
    dispatch({ type: SET_MILESTONE_BENEFIT, payload: milestone_benefit });
  };

  const createMilestone_benefit = () => {
    dispatch({ type: CREATE_MILESTONE_BENEFIT });
  };

  const setPropertyMilestone_benefit = (key, value) => {
    dispatch({ type: SET_PROPERTY_MILESTONE_BENEFIT, payload: { key, value } });
  };

  const saveMilestone_benefit = (milestone_benefit, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = MilestoneBenefitsService.putMilestone_benefit;
    if (isNaN(parseInt(milestone_benefit.milestone_benefit_id))) {
      service = MilestoneBenefitsService.postMilestone_benefit;
    }
    service(milestone_benefit)
      .then(() => {
        success("Milestone_benefit saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteMilestone_benefit = (milestone_benefit_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    MilestoneBenefitsService.deleteMilestone_benefit(milestone_benefit_id)
      .then(() => {
        success("Milestone_benefit deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <MilestoneBenefitsContext.Provider
      value={{
        ...state,
        setMilestone_benefit,
        getMilestoneBenefits,
        saveMilestone_benefit,
        deleteMilestone_benefit,
        createMilestone_benefit,
        getSingleMilestone_benefit,
        setPropertyMilestone_benefit,
      }}
    >
      {children}
    </MilestoneBenefitsContext.Provider>
  );
};
