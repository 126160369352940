import React, { useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import useSchedule from "../../hooks/useSchedule";
import moment from "moment";

const MonterreyScheduleHeader = () => {
  const { start_date } = useSchedule();

  return (
    <div className="row w-100 text-black bold schedule__header justify-content-between mx-auto ">
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type mb-1">TREN INFERIOR</p>
          <p className="schedule__day mb-1">Lunes</p>
          <p className="schedule__day mb-1 fw-normal">{moment(start_date).format("DD / MMM")}</p>
        </div>
      </div>
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type mb-1">TREN SUPERIOR</p>
          <p className="schedule__day mb-1">Martes</p>
          <p className="schedule__day mb-1 fw-normal">{moment(start_date).add(1, "day").format("DD / MMM")}</p>
        </div>
      </div>
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type mb-1">TREN INFERIOR</p>
          <p className="schedule__day mb-1">Miércoles</p>
          <p className="schedule__day mb-1 fw-normal">{moment(start_date).add(2, "days").format("DD / MMM")}</p>
        </div>
      </div>
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type mb-1">TREN SUPERIOR</p>
          <p className="schedule__day mb-1">Jueves</p>
          <p className="schedule__day mb-1 fw-normal">{moment(start_date).add(3, "days").format("DD / MMM")}</p>
        </div>
      </div>
      <div className="col">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type mb-1">TREN INFERIOR</p>
          <p className="schedule__day mb-1">Viernes</p>
          <p className="schedule__day mb-1 fw-normal">{moment(start_date).add(4, "days").format("DD / MMM")}</p>
        </div>
      </div>
      <div className="col pe-0">
        <div className="day-type__container mx-auto">
          <p className="schedule__day-type mb-1">FULL BODY</p>
          <p className="schedule__day mb-1">Sábado</p>
          <p className="schedule__day mb-1 fw-normal">{moment(start_date).add(5, "days").format("DD / MMM")}</p>
        </div>
      </div>
    </div>
  );
};

export default MonterreyScheduleHeader;
