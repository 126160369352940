import React, { useContext, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";

const VerifyWhatsApp = () => {
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");

  const { error, spinner, requestCode, verifyCode } =
    useContext(CustomerContext);
  const { clearModal } = useContext(ModalContext);
  const { getUsuario } = useContext(AuthContext);

  const renderStep = () => {
    if (step === 1) {
      return (
        <div>
          <p>
            Al verificar tu WhatsApp, recibirás notificaciones rápidas sobre
            cambios en tus clases, actualizaciones de horarios, y recordatorios
            personalizados para que no te pierdas nada.
          </p>
          <div className="row">
            <div className="col-6">
              <button onClick={clearModal} className="btn w-100 text-muted">
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary w-100"
                onClick={() => {
                  requestCode();
                  setStep(2);
                }}
                disabled={spinner}
              >
                {spinner ? <div className="spinner-border" /> : "Continuar"}
              </button>
            </div>
          </div>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div>
          <p>Escribe el código que recibiste por WhatsApp.</p>
          <input
            type="text"
            value={code}
            className="form-control mb-3"
            onChange={(e) => setCode(e.target.value)}
          />
          {error !== "" && (
            <p className="text-danger">El código es incorrecto</p>
          )}
          <div className="row">
            <div className="col-6">
              <button onClick={clearModal} className="btn w-100 text-muted">
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button
                disabled={spinner}
                onClick={() => verifyCode(code, getUsuario)}
                className="btn btn-primary w-100"
              >
                {spinner ? <div className="spinner-border" /> : "Verificar"}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return <div>{renderStep()}</div>;
};

export default VerifyWhatsApp;
