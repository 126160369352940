
import React, { createContext, useReducer, useContext } from 'react';
import CustomerMilestonesService from '../services/CustomerMilestonesService';
import CustomerMilestonesReducer from '../reducers/CustomerMilestonesReducer';
import {
  CUSTOMERMILESTONES_RECEIVED,
  SET_CUSTOMERMILESTONE,
  CREATE_CUSTOMERMILESTONE,
  SET_PROPERTY_CUSTOMERMILESTONE,
} from "../types/customer_milestones";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  customer_milestones: null,
  customer_milestone: null,
};

export const CustomerMilestonesContext = createContext(initialState);

export const CustomerMilestonesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerMilestonesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCustomerMilestones = () => {
    CustomerMilestonesService.getCustomerMilestones()
      .then((response) => {
        const { customer_milestones } = response.data;
        dispatch({ type: CUSTOMERMILESTONES_RECEIVED, payload: customer_milestones });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleCustomerMilestone = (CustomerMilestone_id) => {
    CustomerMilestonesService.getSingleCustomerMilestone(CustomerMilestone_id)
      .then((response) => {
        const { customer_milestone } = response.data;
        dispatch({ type: SET_CUSTOMERMILESTONE, payload: customer_milestone });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setCustomerMilestone = (customer_milestone) => {
    dispatch({ type: SET_CUSTOMERMILESTONE, payload: customer_milestone });
  };

  const createCustomerMilestone = () => {
    dispatch({ type: CREATE_CUSTOMERMILESTONE });
  };

  const setPropertyCustomerMilestone = (key, value) => {
    dispatch({ type: SET_PROPERTY_CUSTOMERMILESTONE, payload: { key, value } });
  };

  const saveCustomerMilestone = (customer_milestone, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = CustomerMilestonesService.putCustomerMilestone;
      if(isNaN(parseInt(customer_milestone.CustomerMilestone_id))) {
        service = CustomerMilestonesService.postCustomerMilestone;
      }
      service(customer_milestone).then(() => {
        success("customer_milestone saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteCustomerMilestone = (CustomerMilestone_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    CustomerMilestonesService.deleteCustomerMilestone(CustomerMilestone_id).then(() => {
      success("customer_milestone deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <CustomerMilestonesContext.Provider
    value={{
      ...state,
      setCustomerMilestone,
      getCustomerMilestones,
      saveCustomerMilestone,
      deleteCustomerMilestone,
      createCustomerMilestone,
      getSingleCustomerMilestone,
      setPropertyCustomerMilestone,

    }}
  >
    {children}
  </CustomerMilestonesContext.Provider>
);
};
