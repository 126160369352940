import React from "react";
import { S3_ENDPOINT } from "../../utils";

const VideoImage = ({ video, thumbnail }) => {
  const renderImg = () => {
    if (video.file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${video.file.name}.${video.file.type}`}
          className={thumbnail ? "profile-thumbnail" : "mw-100 w-100"}
          alt={video.name}
        />
      );
    }
    return (
      <div className="btn btn-round shadow-sm btn-light">
        <i className="fa fa-play"></i>
      </div>
    );
  };
  return <div>{renderImg()}</div>;
};

export default VideoImage;
