import React, { createContext, useContext, useReducer } from "react";
import ReservationsReducer from "../reducers/ReservationsReducer";
import ReservationsService from "../services/ReservationsService";
import { RESERVATIONS_RECIBIDAS } from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import { AuthContext } from "./AuthContext";
import { SET_MAX } from "../types/posts";

const initialState = {
  reservations: null,
  reservation: null,
  updated: false,
  max: null,
};

export const ReservationsContext = createContext(initialState);

export const ReservationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReservationsReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  const { getUsuario } = useContext(AuthContext);

  const getMyReservations = (filters) => {
    ReservationsService.getMyReservations(filters).then((res) => {
      const { total, class_reservations } = res.data;
      dispatch({ type: SET_MAX, payload: total });
      dispatch({ type: RESERVATIONS_RECIBIDAS, payload: class_reservations });
    });
  };

  const cancelReservacion = (class_reservation_id, callback) => {
    ReservationsService.cancelReservation(class_reservation_id)
      .then(() => {
        hideModal();
        success("¡Reservación cancelada con éxito!");
        getMyReservations();
        getUsuario();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            hideModal();
            alert(
              "Lo sentimos, para cancelar tu clase necesitas al menos 1 hora de anticipación."
            );
          }
        }
        getMyReservations();
        getUsuario();
      });
  };

  const postReservacion = (clase) => {
    let service;
    if (clase.class_reservation_id) {
      service = ReservationsService.putReservacion(clase);
    } else {
      service = ReservationsService.postReservation(clase);
    }
    service
      .then(() => {
        hideModal();
        success("¡Reservación guardada con éxito!");
        getMyReservations();
        getUsuario();
      })
      .catch((error) => {
        getUsuario();
        getMyReservations();
        if (error.response) {
          if (
            [412, 409].includes(error.response.status) &&
            typeof error.response.data === "object"
          ) {
            return alert(error.response.data.message);
          }
        }
        alert(error);
      });
  };

  const postAttend = (class_reservation_id, attend) => {
    ReservationsService.postAttend(class_reservation_id, attend).then(() => {
      success(attend ? "Asistencia registrada." : "Asistencia cancelada");
    });
  };

  const updateGuestName = (class_reservation_id, name) => {
    ReservationsService.updateGuestName(class_reservation_id, name).then(() => {
      success("¡Nombre actualizado!");
    });
  };

  const eliminarReservacion = (class_reservation_id) => {
    ReservationsService.deleteAsistente(class_reservation_id).then(() => {
      success("¡Reservacion eliminada!");
      hideModal();
    });
  };

  const saveRating = (video_rating) => {
    ReservationsService.postVideoRating(video_rating)
      .then(() => {
        success("Video calificado");
        clearModal();
      })
      .catch((error) => {
        alert(error);
        clearModal();
      });
  };

  const clearReservations = () => {
    dispatch({ type: RESERVATIONS_RECIBIDAS, payload: null });
  };

  return (
    <ReservationsContext.Provider
      value={{
        ...state,
        postAttend,
        saveRating,
        postReservacion,
        updateGuestName,
        clearReservations,
        cancelReservacion,
        getMyReservations,
        eliminarReservacion,
      }}
    >
      {children}
    </ReservationsContext.Provider>
  );
};
