import { Link, navigate } from "@reach/router";
import React, { useContext, useEffect } from "react";
import SignUpForm from "../components/auth/SignUpForm";
import { AuthContext } from "../context/AuthContext";

const SignUp = () => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user !== null && !window.location.href.includes("mytbm")) {
      navigate("/mytbm");
    }
  }, [user]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 vh-100 px-0 hide-mobile bg-dark">
          <div className="bg-vertical"></div>
        </div>
        <div className="col-12 col-md-6 vh-100">
          <div className="row align-items-center vh-100">
            <div className="container-fluid">
              <Link to="/">
                <img
                  alt="logo"
                  src="/img/logo.png"
                  className="logo-login d-block m-auto"
                />
              </Link>
              <h1 className="text-center mb-3">¡Regístrate!</h1>
              <SignUpForm />
              <div className="login-card mt-4">
                <Link to="/" className="text-muted">
                  <i className="fa fa-home me-1"></i> Regresar a Inicio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
