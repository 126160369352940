
import {
  SET_CUSTOMERMILESTONE,
  CREATE_CUSTOMERMILESTONE,
  CUSTOMERMILESTONES_RECEIVED,
  SET_PROPERTY_CUSTOMERMILESTONE,
} from "../types/customer_milestones";

const schema = {

}

const CustomerMilestonesReducer = (state, { type, payload }) => {
  switch (type) {
    case CUSTOMERMILESTONES_RECEIVED:
      return { ...state, customer_milestones: payload };
    case SET_CUSTOMERMILESTONE:
      return { ...state, customer_milestone: payload };
    case CREATE_CUSTOMERMILESTONE:
      return { ...state, customer_milestone: schema };
    case SET_PROPERTY_CUSTOMERMILESTONE: {
      const { key, value } = payload;
      const customer_milestone = { ...state.customer_milestone };
      customer_milestone[key] = value;
      return { ...state, customer_milestone };
    }
    default:
      return { ...state};
  }
};

export default CustomerMilestonesReducer;
