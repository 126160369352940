import React, { createContext, useReducer } from "react";
import LocationsReducer from "../reducers/LocationsReducer";
import LocationsServices from "../services/LocationsServices";
import {
  CREATE_LOCATION,
  LOCATIONS_RECIBIDAS,
  SET_LOCATION,
  SET_PROPIEDAD_LOCATION,
  SET_CITIES,
  SET_COUNTRIES,
  SET_STATES,
} from "../types/locations";

const initialState = {
  locations: null,
  countries: null,
  states: null,
  cities: null,
};

export const LocationsContext = createContext(initialState);

export const LocationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LocationsReducer, initialState);

  const getLocations = (filters) => {
    LocationsServices.getLocations(filters).then((res) => {
      const { locations } = res.data;
      dispatch({ type: LOCATIONS_RECIBIDAS, payload: locations });
    });
  };

  const getLocationsByBranch = (filters, branch_id) => {
    LocationsServices.getLocationsByBranch(filters, branch_id).then((res) => {
      const { locations } = res.data;
      dispatch({ type: LOCATIONS_RECIBIDAS, payload: locations });
    });
  };

  const getCountries = () => {
    LocationsServices.getCountries().then((res) => {
      const { countries } = res.data;
      dispatch({ type: SET_COUNTRIES, payload: countries });
    });
  };

  const getStates = (country_code) => {
    LocationsServices.getStates({ country_code }).then((res) => {
      const { states } = res.data;
      dispatch({ type: SET_STATES, payload: states });
    });
  };

  const getCities = (country_code, state_code) => {
    LocationsServices.getCities({ country_code, state_code }).then(
      (res) => {
        const { cities } = res.data;
        dispatch({ type: SET_CITIES, payload: cities });
      }
    );
  };

  const setLocation = (location) => {
    dispatch({ type: SET_LOCATION, payload: location });
  };

  const createLocation = () => {
    dispatch({ type: CREATE_LOCATION });
  };

  const setPropiedadLocation = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_LOCATION, payload: { key, value } });
  };

  const postLocation = (location) => {
    if (isNaN(location.location_id)) {
      LocationsServices.postLocation(location).then(() => {
        getLocations();
      });
    } else {
      LocationsServices.putLocation(location).then(() => {
        getLocations();
      });
    }
  };

  const deleteLocation = (location_id) => {
    LocationsServices.deleteLocation(location_id).then(() => {
      getLocations();
    });
  };

  return (
    <LocationsContext.Provider
      value={{
        ...state,
        getStates,
        getCities,
        setLocation,
        getCountries,
        getLocations,
        postLocation,
        createLocation,
        deleteLocation,
        getLocationsByBranch,
        setPropiedadLocation,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
