import React from "react";
import MonterreyScheduleRow from "./MonterreyScheduleRow";

const MonterreyScheduleClasses = ({ morningClasses, eveningClasses, startsAm }) => {

  const renderMorningRows = () => {
    if (Object.keys(morningClasses).length > 0) {
      return Object.keys(morningClasses)
        .map((hour) => {
          return (
            <MonterreyScheduleRow
              key={`${hour}-am`}
              hour={hour}
              days={morningClasses[hour]}
            />
          );
        });
    }
  };

  const renderEveningRows = () => {
    if (Object.keys(eveningClasses).length > 0) {
      return Object.keys(eveningClasses)
        .map((hour) => {
          return (
            <MonterreyScheduleRow
              key={`${hour}-pm`}
              hour={hour}
              days={eveningClasses[hour]}
            />
          );
        });
    }
  };
  
  const renderDivision = () => {
    if(startsAm) {
      return(
        <div
          className="row class-hour__row bold text-black align-items-center mx-auto justify-content-between w-100"
          style={{ height: "80px" }}
        >
        </div>
      );
    }
  }

  return (
    <>
      {renderMorningRows()}
      {renderDivision()}
      {renderEveningRows()}
    </>
  );
};

export default MonterreyScheduleClasses;
