import React, { useContext, useEffect, useState } from "react";
import { PlaybacksContext } from "../../context/PlaybacksContext";
import { VideosContext } from "../../context/VideosContext";
import { ModalContext } from "../../context/ModalContext";
import VideoRating from "./VideoRating";
import ReactPlayer from "react-player";

const VideoPlayer = ({ video, video_id, customer_id }) => {
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);

  const { sendRateEmail } = useContext(VideosContext);
  const { playback, savePlayback } = useContext(PlaybacksContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    if (playedSeconds >= duration * 0.5) {
      if (!emailSent) {
        sendRateEmail(video.video_id);
        setEmailSent(true);
      }
    }
    if (duration > 0 && playback !== null) {
      const progress = parseInt((playedSeconds / duration) * 100);
      if (progress > playback.progress) {
        savePlayback({ ...playback, progress });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playback, playedSeconds]);

  const handlePlayback = () => {
    savePlayback({ video_id, customer_id });
  };

  const handlePause = () => {
    if (playedSeconds >= duration * 0.9 && playback !== null) {
      modalComponent(
        "Califica tu Entrenamiento",
        <VideoRating
          video_id={video.video_id}
          handleCancel={clearModal}
          playback_id={playback.playback_id}
        />,
        { size: "lg" }
      );
    }
  };

  const renderPlayer = () => {
    return (
      <ReactPlayer
        controls
        width="100%"
        height="80vh"
        url={video.url}
        playing={isPlaying}
        onPause={handlePause}
        onStart={handlePlayback}
        onDuration={(seconds) => setDuration(seconds)}
        onProgress={(data) => setPlayedSeconds(data.playedSeconds)}
      />
    );
  };

  return <div>{renderPlayer()}</div>;
};

export default VideoPlayer;
