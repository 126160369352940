import moment from "moment";
import React, { useState, useContext } from "react";
import SelectWeekDays from "../global/SelectWeekDays";
import { WorkoutPlansContext } from "../../context/WorkoutPlansContext";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";

const today = moment().format("yyyy-MM-DD");

const ApplyWorkoutPlanner = ({
  handleCancel,
  handleCallback,
  workout_plan_id,
}) => {
  const [weekDays, setWeekDays] = useState([]);
  const [startDate, setStartDate] = useState(today);
  const { workout_plan } = useContext(WorkoutPlansContext);
  const { saveCustomerWorkoutPlan } = useContext(CustomerWorkoutPlansContext);

  const callback = () => {
    if (typeof handleCallback === "function") {
      handleCallback();
    }
    handleCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveCustomerWorkoutPlan(
      {
        end_date: moment(startDate).add(4, "weeks").format("YYYY-MM-DD"),
        week_days: Array.from(weekDays).toString(),
        start_date: startDate,
        workout_plan_id,
      },
      callback
    );
  };

  const invalidDays = weekDays?.length !== parseInt(workout_plan?.frequency);

  return (
    <form onSubmit={handleSubmit}>
      <p>¿Cuándo deseas empezar este Workout Planner?</p>
      <p>
        El Workout Planner tiene una duración de 1 mes a partir de la fecha que
        lo apliques.
      </p>
      <p>Puedes cambiarlo en cualquier momento.</p>
      <input
        type="date"
        min={today}
        value={startDate}
        className="form-control mb-4"
        onChange={(e) => {
          const todayStart = moment().startOf("day");
          const value = moment(e.target.value).startOf("day");
          if (value.isSameOrAfter(todayStart)) {
            setStartDate(value.format("YYYY-MM-DD"));
          }
        }}
      />
      <p>¿Qué días te gustaría entrenar? Elige <span className="text-primary bold">{workout_plan?.frequency}</span> días</p>
      <SelectWeekDays value={weekDays} modifier={setWeekDays} />
      <label className="small mb-3">Haz click en los días para seleccionarlos</label>
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            type="submit"
            disabled={invalidDays}
            className="btn w-100 btn-primary"
          >
            Aplicar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ApplyWorkoutPlanner;
